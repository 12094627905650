import React, { Fragment } from 'react';
import { getCustomerList } from '../../Redux/actions';
import { connect } from 'react-redux';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            customersList: [],
        }
    }

    componentDidMount() {
        this.props.getCustomerList().then(res => {
            if (res && res.data) {
                this.setState({
                    customersList: res.data
                })
            }
        })
    }

    myFunction() {
        this.setState(prevState => ({
            show: !prevState.show
        }));
    }



    render() {
        const { customersList } = this.state;
        const { client } = this.props;
        let logo = null;
        if (customersList.length > 0) {
            const item = customersList.find(item => item.key == client)
            if (item) {
                logo = item.logo;
            }

        }


        return (
            <div className="w3-bar w3-border w3-border-bottom">
                <a href="#" className="w3-bar-item">{logo && <img src={logo} style={{ height: "50px" }} />}</a>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, {
    getCustomerList,
})(Header);



