import React from 'react';
import { InputTextField } from '../Common/HelperInputs';
import {
    Button, Grid, FormControlLabel, Checkbox,
} from '@material-ui/core';

import {AbilityContext} from '../../Ability';
import Ticket from './Ticket.dao';

export default class Compose extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                issueDiscussion: '',
                notifyCustomer: true,
                notifyMerchant: false,
                status: '',
            },
            errors: {},
            inFlight: false,
            showAppMessage: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    componentDidMount() {
        this.setCallDetails(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.callDetails && (nextProps.callDetails != this.props.callDetails)) {
            this.setCallDetails(nextProps);
        }
    }

    setCallDetails(props) {
        const { callDetails } = props;

        if (callDetails && callDetails.meetingParams) {
            const {
                meetingParams: {
                    meetingResponse,
                    password
                },
                startTime
            } = callDetails;
            const { join_url } = meetingResponse;
    
            const callTemplate = `
                Call Scheduled,
    
                A zoom call has been scheduled for ${startTime}.
                
                Join url - ${join_url}
                
                Join password - ${password}
            `;
    
            const { formData } = this.state;
            formData['issueDiscussion'] = callTemplate;
            this.setState({
                formData
            });
        }
    }

    changeValue(name, value) {
        const { formData, errors } = this.state;
        const { ticketDetails } = this.props;
        formData[name] = value;
        if (errors[name]) {
            errors[name] = null;
        }

        this.setState({ formData, errors });

    }

    handleChange(evt) {
        const { name, value } = evt.target;
        this.changeValue(name, value)
    }

    validateData(data) {
        const { errors } = this.state;
        let isValid = true;
        const ignoreList = [
            'notifyCustomer',
            'notifyMerchant',
            'status',
        ];

        Object.keys(data).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (data[key]) === 'string') {
                    if (!data[key].match(/\w/)) {
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!data[key]) {
                    errors[key] = 'This field is mandatory.';
                }
            }
            if (errors[key]) {
                isValid = false;
            }
        });

        if (Object.keys(errors).length > 0 && !isValid) {
            this.setState({ errors });
        }

        return isValid;
    }

    handleUpdateStatus(data = {}) {
        const { postUpdateTicket, ticketDetails, callDetails, resetCallDetails } = this.props;
        const { formData } = this.state;
        if (!this.validateData(formData)) {
            return false;
        }
        if (callDetails) {
            callDetails.meetingParams['message'] = formData.issueDiscussion;
            data = Object.assign({}, callDetails);
        }

        const updateTicket = Object.assign({
            actionToken: ticketDetails.actionToken,
        }, data, formData);

        this.setState({ inFlight: true });

        postUpdateTicket(updateTicket).then((resp) => {
            this.setState({
                showAppMessage: true,
                message: 'Updated Successfully',
            });
            this.clearFormData();
            callDetails && resetCallDetails()
            this.setState({ inFlight: false});
        });

    }

    clearFormData() {
        const { formData } = this.state;
        formData.issueDiscussion = '';
        formData.notifyMerchant = false;
        formData.status = '';
        this.setState({ formData });
    }


    render() {
        const {
            formData, errors, inFlight
        } = this.state;

        let { ticketDetails } = this.props; 
        let disableCustomer = false;
        let disableMerchant = false;

        if(formData.status === 'closed') {
            formData.notifyMerchant = ticketDetails.merchantEmailId != null && formData.status === 'closed';
            formData.notifyCustomer = true;
            disableCustomer = true;
            disableMerchant = true
         }
         
         if (ticketDetails.merchantEmailId == null) {
            disableMerchant = true;
         }

        return (
            <AbilityContext.Consumer>{(ability)=> { 
                const ticket = new Ticket(ticketDetails);
                const disabled = inFlight || !(ability.can('compose', ticket));
                return (<div className="w3-margin-top">
                        <div className="w3-padding">
                            Customer : {ticketDetails.emailId}
                        </div>
                        <Grid item md={12} sm={12} xs={12}>
                            <InputTextField
                                multiline
                                rows="14"
                                placeholder="Compose your message"
                                name="issueDiscussion"
                                id="issueDiscussion"
                                value={(formData.issueDiscussion) ? formData.issueDiscussion : ''}
                                onChange={(e) => this.handleChange(e)}
                                errors={errors.issueDiscussion}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item container md={12} className="w3-panel" justify="space-between">
                            <Grid item md={6}>
                                <FormControlLabel disabled={disabled || disableCustomer} 
                                    control={<Checkbox checked={formData.notifyCustomer} onChange={(e, v) => this.changeValue('notifyCustomer', v)} />}
                                    label="Notify Customer"
                                />
                                <FormControlLabel disabled={disabled || disableMerchant}
                                    control={<Checkbox checked={formData.notifyMerchant} onChange={(e, v) => this.changeValue('notifyMerchant', v)} />}
                                    label="Notify Merchant"
                                />
                            </Grid>
                            <Grid container item md={6} justify="flex-end">
                                <FormControlLabel disabled={disabled}
                                    control={<Checkbox checked={formData.status === 'closed'} onChange={(e, v) => this.changeValue('status', v ? 'closed' : 'active')} />}
                                    label="Mark Resolved"
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={(e) => this.handleUpdateStatus()}
                                    disabled={disabled}
                                >
                                    Update Progress
                        </Button>
                            </Grid>
                        </Grid>
                        <div className="w3-padding">
                        </div>
                    </div>)
            }}
            </AbilityContext.Consumer>
            
        )
    }
}