import { createContext } from 'react';
import { createContextualCan } from '@casl/react';

import { AbilityBuilder, Ability } from '@casl/ability'

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);



export const getAbility = () => {
    return new Ability([]);
}


export const updateAbility = (roles, ability) => {

    const { can, cannot, rules } = new AbilityBuilder();
    // // can read blog posts
    // can('read', 'BlogPost');
    // // can manage (i.e., do anything) own posts
    // can('manage', 'BlogPost', { author: user.id });
    // // cannot delete a post if it was created more than a day ago
    // cannot('delete', 'BlogPost', {
    //     createdAt: { $lt: Date.now() - 24 * 60 * 60 * 1000 }
    // });
    roles.forEach(element => {
        if (element.roles.type == 'admin') {
            can('view', 'User management');
        }

        if (element.roles.type === 'admin' || element.roles.type == 'mediator') {
            can('view', 'Dashboard');
        } else {
            can('view', 'Tickets');
            can('view', 'Customer Support');
            can('compose', 'Ticket', {
                phase: 'Tier 1', status: {
                    $ne: 'closed'
                }
            });
        }

        if (element.roles.type == 'mediator') {
            can('compose', 'Ticket', {
                phase: 'Tier 3',
                status: {
                    $ne: 'closed'
                },
                mediatorId: element.userId
            });
        }

        can('view', 'Settings');

    });
    return ability.update(rules);
}