import React, { useEffect, useState, useContext} from 'react';
import './App.scss';
import LayoutRouter from './Router/LayoutRouter';
import AppRouter from './Router/AppRouter';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserRoles } from './Redux/actions';
import { CircularProgress } from '@material-ui/core';
import { AbilityContext ,updateAbility } from './Ability';

const App: React.FC = () => {


	const ability = useContext(AbilityContext); 
	const dispatch: any = useDispatch();
	const state: any = useSelector(state => state);
	const { currentUser, currentUserRoles } = state;
	const [ user, setUser ] = useState(null);

	useEffect(() => {
		dispatch(getCurrentUser()).then((resp: any) => {
			const res = resp && resp.data;
			if (res && res.success && res.data) {
				dispatch(getCurrentUserRoles());
				setUser(res.data);
			}
		});
	}, [dispatch]);

	useEffect(()=>{
		if (!currentUserRoles || !currentUserRoles.data || !currentUserRoles.data.data){
			return;
		}
		updateAbility(currentUserRoles.data.data,ability);
	},[currentUserRoles])


	if (!currentUser || currentUser.isFetching) {
		return (
			<div className="textMarginCenter">
				<CircularProgress />
			</div>
		)
	}
	if (user ) {
		if (!currentUserRoles || currentUserRoles.isFetching) {
			return <div className="textMarginCenter">
				<CircularProgress />
			</div>
		}
		return <AppRouter />
	} else {
		return <LayoutRouter />
	}
}

export default App;
