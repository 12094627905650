export default {

    // auth
    currentUser: {
        path: '/api/v1/auth/user',
    }, 
    logout: {
        path: '/api/v1/auth/logout',
    },
    currentUserRoles: {
        path: '/api/v1/roles/current-user-roles',
    },
    firstUser: {
        path: '/api/v1/auth/first-user'
    },
    updateAdminStatus: {
        path: '/api/v1/roles/update-user-role',
        method: 'POST'
    },
    getRoleUsers: {
        path: '/api/v1/roles/get-role-users',
        method: 'GET'
    },
    register: {
        path: '/api/v1/auth/register',
        method: 'POST',
    },
    login: {
        path: '/api/v1/auth/login',
        method: 'POST',
    },
    forgotPassword: {
        path: '/api/v1/auth/forgot-password',
        method: 'POST',
    },
    resetPassword: {
        path: '/api/v1/auth/reset-password',
        method: 'POST',
    },
   
    changeUserPassword: {
        path: '/api/v1/auth/change-password',
        method: 'PUT',
    },
    changeUserName: {
        path: '/api/v1/auth/change-username',
        method: 'PUT',
    },

    // users

    usersList: {
        path: '/api/v1/auth/all-users',
        method: 'GET',
    },

    //Enquiries
    createEnquiry: {
        path: '/api/v1/enquiries/create',
        method: 'POST',
    },
    enquiryList: {
        path: '/api/v1/enquiries/all-enquiries'
    },
    updateEnquiry: {
        path: '/api/v1/enquiries/update-status',
        method: 'PUT'
    },

    //DashBoard
    enquiryStats: {
        path: '/api/v1/enquiries/dash-enq-stats',
    },

    sheetdata: {
        path: '/api/v1/configs/sheet-data',
        method: 'POST'
    },

    customerList: {
        path: '/api/v1/customer/customer-list',
        method: 'GET'
    },

    // meta

    getAllMeta: {
        path: '/api/v1/meta/all-meta',
    },

    addMeta: {
        path: '/api/v1/meta/add-meta',
        method: 'POST',
    },

    getMetaByName: {
        path: '/api/v1/meta/get-meta-byname',
    },

    deleteMeta: {
        path: '/api/v1/meta/delete',
        method: 'DELETE',
    },
    versionNumber: {
        path: '/api/v1/meta/version',
    },
// Terms

    getAllTerms: {
        path: '/api/v1/meta/all-terms',
    },
    addTerm: {
        path: '/api/v1/meta/add-term',
        method: 'PUT',
    },
    getTermById: {
        path: '/api/v1/meta/get-term',
    },
    updateTerm: {
        path: '/api/v1/meta/update',
        method: 'PUT',
    },
    deleteTerm: {
        path: '/api/v1/meta/delete-term',
        method: 'DELETE',
    },
    findTerm: {
        path: '/api/v1/meta/find-meta-term',
    },


    //invite
    invitedUserList: {
        path: '/api/v1/invites/all-invites',
    },
    inviteUser: {
        path: '/api/v1/invites/create',
        method: 'POST',
    },
    editInviteUser: {
        path: '/api/v1/invites/update',
        method: 'PUT',
    },
    deleteInviteUser: {
        path: '/api/v1/invites/delete',
        method: 'DELETE',
    }, 
    acceptInvite: {
        path: '/api/v1/invites/accept',
        method: 'POST',
    },
    validateToken: {
        path: '/api/v1/invites/validate-token',
    },



    //create-ticket

    createTicket: {
        path: '/api/v1/ticket/create',
        method: 'POST',
    },

    //all-tickets

    allTickets: {
        path: '/api/v1/ticket/all-tickets',
    },

    allDashboardTickets: {
        path: '/api/v1/ticket/dashboard-tickets'
    },

    //ticket-details

    ticketById: {
        path: '/api/v1/ticket/get-ticket',
    },

    updateTicket: {
        path: '/api/v1/ticket/update-ticket',
        method: 'POST'
    },

    clientTicketUpdate :{
        path: '/api/v1/ticket/client-ticket-update',
        method: 'POST'
    },

    zoomCallStatus: {
        path: '/api/v1/ticket/set-zoom-call-status',
        method: 'POST'
    },

    //rating-trigger

    ratingTrigger: {
        path: '/api/v1/customer/rating-trigger',
        method: 'GET'
    },

    //configarable time

    closureEmailTime: {
        path: 'api/v1/configs/closure-email-time',
        method: 'GET'
    },
    customerDetails: {
        path: '/api/v1/customer/customer-details',
        method: 'GET'
    },
    uploadedFile: {
        path: '/api/v1/ticket/file',
        method: 'GET'
    },
    storeSessionInfo: {
        path: '/api/v1/ticket/store-session-info',
        method: 'POST'
    },
    sessionInfo: {
        path: '/api/v1/ticket/get-session-info',
        method: 'GET'
    },
    mediatiorsList: {
        path: '/api/v1/roles/mediator-role-users',
        method: 'GET'
    },
    userUpdateTicket: {
        path: '/api/v1/ticket/user-update-ticket',
        method: 'POST'
    },

    logoBasedOnRole: {
        path: '/api/v1/roles/logo-based-roles'
    },
    updateTimeline: {
        path: '/api/v1/ticket/update-timeline',
        method: 'POST'
    },

    //Zoom apis

    zoomAuth: {
        path: '/api/v1/auth/zoom-auth',
        method: 'POST'
    },
    zoomUserDetails: {
        path: '/api/v1/auth/zoom-user-details',
        method: 'POST'
    },
    zoomMeetingDetails: {
        path: '/api/v1/auth/create-zoom-meeting',
        method: 'POST'
    }
};
