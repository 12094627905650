import React from 'react';
import { Checkmark } from 'react-checkmark';
import { postUserUpdateTicket } from '../../Redux/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


 
class RuleLink extends React.Component {
    constructor(args) {
        super(args);
        this.state = {
            response: null,
            message: null,
            clsMessage:null,
        }
    }

    componentDidMount() {
        const { token, option } = this.props;
        const postData = {
            status: option,
            actionToken : token,
        };
        axios.post('/api/v1/ticket/user-update-ticket',postData).then((resp) => { 
            this.setState({
                clsMessage: resp.data.next === "closed" ? 'w3-text-green':'w3-text-red',
                response: resp.data.next,
                message: resp.data.next === "closed" ? "We are happy to know that our resolution offered has been accepted by you. We will update the system."
                    : "We are sorry your issue has not been resolved yet, we will get in touch with via your registered email to resolve it at the earliest "
            
            });
        }).catch(ex => {
            this.setState({
                clsMessage:'w3-text-red',
                message:'We are unable to get details about your ticket',
                response:'error'
            })
        });

    }

    render() {
        const { response,message,clsMessage } = this.state;
        return (
            <div>
                {response === null &&  <div className="w3-center w3-padding-64" style={{ marginTop: '100px' }}><CircularProgress /></div>}

                { response !== null  && <div className="w3-center w3-padding" style={{ marginTop: '100px' }}>
                        <div className="w3-card w3-round w3-padding-64 w3-white w3-margin-top" style={{ maxWidth: '600px', margin: 'auto' }}>
                        <div style={{ width: '100px', height: '100px', margin: 'auto' }}>
                            {clsMessage === "w3-text-green" ? <Checkmark size="xx-large" /> : <ErrorOutlineIcon color="error" className="fontSize60" /> }
                        </div>
                            <h4 className={`w3-padding ${clsMessage}`}>
                              {message}
                            </h4>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, {
    postUserUpdateTicket,
})(RuleLink);
