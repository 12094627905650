import React from 'react';
import moment from 'moment';
import { InputTextField } from '../Common/HelperInputs';
import {
    Button, Grid, Card, Chip, Divider
} from '@material-ui/core';
import { TicketStatus } from './TicketStatus';
import Compose  from './Compose';

export class EditForm extends React.Component {
    constructor(props) {
        super(props);
        const { ticketDetails } = props;

        this.state = {
            formData: {
                firstName: ticketDetails.firstName,
                lastName: ticketDetails.lastName,
                address: ticketDetails.address,
                status: ticketDetails.status,
                resolutionOffered: '',
                merchantEmailId: ticketDetails.merchantEmailId,
            },
            errors: {},
            inFlight: false,
            showAppMessage: false, 
        };
        this.handleChange = this.handleChange.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(name, value) {
        const { formData, errors } = this.state;
        formData[name] = value;
        if (errors[name]) {
            errors[name] = null;
        }
        this.setState({ formData, errors });

    }

    handleChange(evt) {
        const { name, value } = evt.target;
        this.changeValue(name, value)
    }

    handleUpdateStatus(data = {}) {
        const { postUpdateTicket, ticketDetails } = this.props;
        let { formData } = this.state;
        const updateTicket = Object.assign({
            actionToken: ticketDetails.actionToken,
        }, data, formData);

        this.setState({ inFlight: true })
        postUpdateTicket(updateTicket).then((response) => {
            this.setState({
                showAppMessage: true,
                message: 'Updated Successfully',
            });
            this.setState({ inFlight: false, formData})
        });

    }

    handleDatePicker(evt, date, id) {
        const { formData, errors } = this.state;
        const formatedDate = moment(evt).format('YYYY/MM/DD');

        if (errors[id]) {
            errors[id] = null;
        }

        formData[id] = formatedDate;
        this.setState({ formData, errors });
    }


    render() {
        const {
            formData, errors, inFlight,
        } = this.state;

        const { ticketDetails } = this.props;


        const disabled = ticketDetails.phase !== 'Tier 1' || ticketDetails.status === "closed" || inFlight;

        
        return (
            <Grid container spacing={0} justify="space-between" className="w3-margin-top ">

                <Grid item container spacing={2} md={4} sm={12} xs={12}>
                    <Card >
                        <Grid item container md={12} sm={12} xs={12} className="w3-padding">
                            <Grid item container xs={12} md={12} justify="space-between" className="w3-margin-bottom w3-margin-top">
                                <Grid item xs={6} md={6}><b >Ticket ID : {ticketDetails.id}</b></Grid>
                                <Grid item container xs={6} md={6} justify="flex-end">
                                    <Chip size="small" className={`w3-margin-right ${ticketDetails.status === 'closed' ? 'w3-green' : 'w3-yellow'}`} label={<span className="capitalizeText">{ticketDetails.status}</span>} />
                                    <Chip size="small" className="w3-blue" label={ticketDetails.phase} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="text-margin-bottom" >Email id : <b>{ticketDetails.emailId}</b></div>
                                <div className="text-margin-bottom">Phone Number : <b>{ticketDetails.phoneNumber}</b></div>
                                <div className="text-margin-bottom">Tier : <b className="capitalizeText">{ticketDetails.phase}</b></div>
                                <div className="text-margin-bottom">Product : <b >{ticketDetails.product}</b></div>
                                <div className="text-margin-bottom">Merchant : <b >{ticketDetails.merchant}</b></div>
                                <div className="text-margin-bottom">Status : <b>{ticketDetails.status}</b></div>
                                <div className="text-margin-bottom">Created : <b>{moment(ticketDetails.createdAt).fromNow()}</b></div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                                <InputTextField
                                    placeholder="Merchant EmailId"
                                    name="merchantEmailId"
                                    id="merchantEmailId"

                                    disabled={disabled}
                                    value={formData.merchantEmailId ? formData.merchantEmailId : ''}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.merchantEmailId}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container md={12} sm={12} xs={12}>
                            <Grid item md={6} sm={12} xs={12}>
                                <InputTextField
                                    placeholder="First Name"
                                    name="firstName"
                                    id="firstName"

                                    disabled={disabled}
                                    value={formData.firstName ? formData.firstName : ''}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.firstName}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <InputTextField
                                    placeholder="Last Name"
                                    name="lastName"
                                    id="Last Name"

                                    disabled={disabled}
                                    value={formData.lastName ? formData.lastName : ''}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.lastName}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                                <InputTextField
                                    multiline
                                    rows="4"
                                    placeholder="Address"
                                    name="address"
                                    id="address"
                                    disabled={disabled}
                                    value={formData.address ? formData.address : ''}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.address}
                                />
                            </Grid>

                            <Grid container justify="flex-end" item md={12} sm={12} xs={12}>
                                <Button
                                    variant="contained"
                                    className="h7t-primary-btn button-styles w3-margin"
                                    onClick={(e) => this.handleUpdateStatus({
                                        phase: 'Tier 1',
                                        state: 'active'
                                    })}
                                    disabled={disabled}
                                >
                                    Update Ticket
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item container spacing={2} md={8} sm={12} xs={12}  >
                     <Card className=" w3-block" >
                        <Grid container item xs={12} sm={12}>
                            <Grid item container className="w3-padding" justify="flex-end" xs={12} sm={12} >
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    className="h7t-primary-btn button-styles w3-margin-right"
                                    onClick={(e) => this.handleUpdateStatus({
                                        phase: 'Tier 2'
                                    })}
                                    disabled={disabled}
                                >
                                    Apply Rules
                                        </Button>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    className="h7t-primary-btn button-styles"
                                    onClick={(e) => this.handleUpdateStatus({
                                        phase: 'Tier 3'
                                    })}
                                    disabled={disabled}
                                >
                                    Human Mediation
                                        </Button>
                            </Grid>

                        </Grid>
                        <Divider className="w3-margin-bottom" />

                        <TicketStatus ticketDetails={ticketDetails} />
                        <Compose ticketDetails={ticketDetails} postUpdateTicket={this.props.postUpdateTicket}/>

                    </Card>
                </Grid>
            </Grid>)
    }
}