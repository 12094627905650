import {
  AppBar,
  Button,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InboxIcon from '@material-ui/icons/Inbox';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getVersion, logout, getLogoBasedOnRole } from '../../Redux/actions'; 
import logo from '../../img/fair.png';
import { Can } from '../../Ability';
import { navigate, usePath } from 'hookrouter';

const img = logo;
const version = '1.1.1';
const drawerWidth = 240;
const versionNumberFrontEnd = '1.1.1';
const useStyles = makeStyles({
  flexGrow: {
    flexGrow: 1
  },
  rightAlign: {
    float: 'right'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  noDecoration: {
    textDecoration: 'none'
  }
});
const Header = () => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(state => state);
  const { currentUser, currentUserRoles } = state;
  const [drawer, setDrawer] = useState(false);
  const path = usePath();
  const [versionNumber, setVersionNumer] = useState();
  const url = path.split('/');
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  const [data, setData] = useState();

  const customerId = currentUserRoles.data.data[0].customerId
  useEffect(() => {
    dispatch(getVersion()).then((resp: any) => {
      if (resp && resp.data) {
        setVersionNumer(resp.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLogoBasedOnRole()).then((resp: any) => {
      if (resp && resp.data && resp.data.data && resp.data.data[0] && resp.data.data[0].customer && resp.data.data[0].customer.key) {

        setData(resp.data.data[0].customer.logo)
        
      }
    });           
}, [dispatch]);

  const menus: any[] = [
    {
      title: 'Dashboard',
      link: '/dashboard',
      icon: <DashboardIcon style={{ color: '#666', marginRight: '4px' }} />
    },
    // {
    //   title: 'User management',
    //   link: '/user_managment',
    //   icon: <GroupIcon style={{ color: '#666', marginRight: '4px' }} />
    // },
    {
      title: 'Tickets',
      link: `/enquiries/${customerId}/user`,
      icon: <InboxIcon style={{ color: '#666', marginRight: '4px' }} />
    },
    {
      title: 'Settings',
      link: '/settings',
      icon: <SettingsIcon style={{ color: '#666', marginRight: '4px' }} />
    }
  ];

  let loginUser = '';
  if (currentUser && currentUser.data) {
    loginUser = currentUser.data.data.name;
  }
  const versioning = (
    <div className={'w3-center'}>
      <span className={'w3-text-grey w3-tiny'}>
        V {versionNumberFrontEnd || ''} -- {versionNumber || ''}
      </span>

    </div>
  );
  const sideBar = (
    <div className="toolbar" style={{ position: 'relative' }}>
      <List>
        {menus.map((item) => {
          const parts = item.link.split('/');
          return <Can I="view" a={item.title} key={item.title}>
            <React.Fragment key={item.title}>
            <ListItem
              button
              key={item.title}
              onClick={() => navigate(item.link)}
              selected={url.includes(parts && parts[1])}
            >
              {item.icon}
              <ListItemText style={{ marginLeft: '4px' }} primary={item.title} />
            </ListItem>
            </React.Fragment>
          </Can>
        })}
      </List>
    </div>
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const headerSection = () => {
    return (
      <AppBar position="fixed" className={`appBar`}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className="menuButton"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            <img src={data || img} style={{ height: '48px' }} alt =""/>
          </Typography>
          <div className={classes.flexGrow}>

            <Button className={`${classes.rightAlign} w3-border w3-white`} color="inherit" aria-haspopup="true" onClick={handleClick}
              endIcon={<ArrowDropDownIcon style={{ fontSize: '25px' }} />}>
              {loginUser}
            </Button>
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              keepMounted
              className="w3-block"
              style={{ width: "200px" }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}

              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 190,
                  left: 1252,
                  width: 134,
                }
              }}
            >
              <MenuItem onClick={() => {
                navigate('/settings');
                handleClose();

              }}>Settings</MenuItem>
              <MenuItem onClick={() => {
                dispatch(logout()).then(() => {
                  localStorage.removeItem('fairsettle_access_token');
                  if (localStorage.getItem('zoom_access_token')) {
                    localStorage.removeItem('zoom_access_token');
                  }
                  navigate('/login');
                  window.location.reload();
                })
              }}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <div className="header-section">
      {headerSection()}
      <Hidden smUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          classes={{
            paper: classes.drawerPaper
          }}
          open={drawer}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {sideBar}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          open
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {sideBar}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Header;
