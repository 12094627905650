import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postRegister } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { TextInputField } from '../Common/HelperInputFields';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { A, navigate } from 'hookrouter';

const useStyles = makeStyles(theme => ({
  formTop: {
    marginTop: '100px'
  }
}));

const panelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15)
    }
  })
);

const Register = () => {
  const classes = useStyles();
  const panel = panelStyles();

  const dispatch: any = useDispatch();
  const initForm: any = {
    uniqueId: '',
    password: '',
    confirm: ''
  };
  const initErr: any = {};
  const [form, setForm] = useState(initForm);
  const [errors, setErrors] = useState(initErr);
  const [alertMessage, setAlertMessage] = useState(null);
  const [passReg, setPassReg] = useState(false);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const fieldValue = Object.assign({}, form);
    const errorField = Object.assign({}, errors);
    if (errorField[name]) {
      errorField[name] = null;
      setErrors(errorField);
    }
    fieldValue[name] = value;
    if (name === 'uniqueId') {
      fieldValue[name] = value.toLowerCase();
    }
    setPassReg(false);
    setForm(fieldValue);
  };

  const validateData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);
    if (!/^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(ceegees.in)$/.test(form.uniqueId))
    {
      hasError = true;
      err.uniqueId = 'Enter a valid FairSettle email.';
    }
    if (form.password !== form.confirm) {
      hasError = true;
      err.confirm = 'Password and confirm password must be same.';
    }

    const regex = /^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*]).{8,}$/;
    if (!regex.test(form.password)) {
      hasError = true;
      setPassReg(true);
      err.password = 'Password Doesnt meet the requirements';
    }

    Object.keys(form).forEach((key) => {
      if (typeof (form[key]) === 'string' && key !== 'password' && key !== 'confirm') {
        if (!form[key].match(/\w/)) {
          hasError = true;
          err[key] = 'This field is required';
        }
      }
      if (!form[key]) {
        hasError = true;
        err[key] = 'This field is required';
      }
    });
    if (hasError) {
      setErrors(err);
      return false;
    }
    return form;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const valid = validateData();
    if (valid) {
      dispatch(postRegister(valid)).then((resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
          setAlertMessage('Registered successfully...!');
        } else if (res && res.data) {
          setErrors(res.data);
        } else {
          setAlertMessage('Something went wrong. Try again...!');
        }
      });
    }
  };
  return (
    <div>
      <Grid container className={classes.formTop} spacing={2} justify="flex-end">
        <Grid item xs={12} sm={4} lg={3} >
          <Card>
            <form onSubmit={(e) => handleSubmit(e)}>
              <CardHeader title="Register"/>
              <CardContent>
                <TextInputField
                  name="uniqueId"
                  placeholder="Email"
                  variant="outlined"
                  margin="dense"
                  value={form.uniqueId}
                  onChange={handleChange}
                  errors={errors.uniqueId}
                />
                <TextInputField
                  type="text"
                  name="name"
                  placeholder="Name"
                  variant="outlined"
                  margin="dense"
                  value={form.name}
                  onChange={handleChange}
                  errors={errors.name}
                />

                <TextInputField
                  type="password"
                  name="password"
                  placeholder="Password"
                  variant="outlined"
                  margin="dense"
                  value={form.password}
                  onChange={handleChange}
                  errors={errors.password}
                />
                {
                  passReg === true &&
                  <div className={panel.root}>
                    <ExpansionPanel>
                      <ExpansionPanelDetails>
                        <Typography className="w3-text-red">
                          <li> Minimum password length 8</li>
                          <li>Require at least one digit</li>
                          <li>Require at least one upper case</li>
                          <li> Require at least one lower case letter</li>
                          <li>Require at least one symbol</li>
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                }
                <TextInputField
                  type="password"
                  name="confirm"
                  placeholder="Confirm Password"
                  variant="outlined"
                  margin="dense"
                  value={form.confirm}
                  onChange={handleChange}
                  errors={errors.confirm}
                />
              </CardContent>

            <CardActions className="padding16">
              <span>Already a member? <A href="/login">Login</A></span>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ marginLeft: 'auto' }}
                onClick={(e) => handleSubmit(e)}
              >Register
              </Button>
            </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
      {alertMessage
        ? (
          <AlertDialog
            handleClose={() => {
              setAlertMessage(null);
              navigate('/login');
            }}
            message={alertMessage}
          />
        ) : null}
    </div>
  );
};

export default Register;
