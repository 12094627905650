import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMediatorsList } from '../../Redux/actions';
import { Grid, FormHelperText, Button } from '@material-ui/core';
import { InputSelectField } from '../Common/HelperInputs';

export const AttorneyModal = ({ ticketDetails, postUpdateTicket, handleClose }) => {
    const dispatch = useDispatch();
    const initForm = {
        mediatorId: '',
        priority: '',
        due: '',
    }
    const initErr = {};
    const [form, setForm] = useState(initForm);
    const [errors, setErrors] = useState(initErr);
    const [mediatorsList, setMediatorsList] = useState(null);

    useEffect(() => {
        dispatch(getMediatorsList()).then((resp) => {
            setMediatorsList(resp.data);
        });
    }, [dispatch]);

    const priorityList = [
        { val: 'High', name: 'High' },
        { val: 'Low', name: 'Low' },
    ];

    const estimationList = [
        { val: '1 day', name: '1 day' },
        { val: '2 days', name: '2 days' },
        { val: '3 days', name: '3 days' },
        { val: '4 days', name: '4 days' },
        { val: '5 days', name: '5 days' },
    ];

    const mediatorOptions = [];

    if (mediatorsList && mediatorsList.data && mediatorsList.data.length) {
        mediatorsList.data.map((item) => {
            mediatorOptions.push({ val: item.user.id, name: item.user.name });
        });
    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        const fieldValue = Object.assign({}, form);
        const errorField = Object.assign({}, errors);
        if (errorField[name]) {
            errorField[name] = null;
            setErrors(errorField);
        }
        fieldValue[name] = value;
        setForm(fieldValue);
    };

    const validateData = () => {
        let hasError = false;
        const err = Object.assign({}, errors);
        Object.keys(form).forEach((key) => {
            if (typeof (form[key]) === 'string' && key !== 'password' && key !== 'confirm') {
                if (!form[key].match(/\w/)) {
                    hasError = true;
                    err[key] = 'This field is required';
                }
            }
            if (!form[key]) {
                hasError = true;
                err[key] = 'This field is required';
            }
        });
        if (hasError) {
            setErrors(err);
            return false;
        }
        return form;
    };

    const handleSubmit = (e, data = {}) => {
        e.preventDefault();
        const valid = validateData();
        if (valid) {
            const mediator = mediatorOptions && mediatorOptions.find((item) => (
                item.val === valid.mediatorId
            ));
            valid.mediatorName = mediator.name;
            const updateTicket = Object.assign({
                actionToken: ticketDetails.actionToken,
            }, data, valid);
            postUpdateTicket(updateTicket).then((resp) => {
                handleClose();
            });
        }
    };

  return(
        <div>
            <h4 className="w3-margin-left font-weight-600">Assign to a mediation officer </h4>
            <Grid container alignItems="center" justify="space-between">
                <Grid item md={12} sm={12} xs={12}>
                    <div className="w3-panel">
                        <FormHelperText className="w3-text-black">Select an attorney</FormHelperText>
                    </div>
                    <InputSelectField
                        name="mediatorId"
                        id="mediatorId"
                        value={form.mediatorId}
                        onChange={handleChange}
                        options={mediatorOptions}
                        errors={errors.mediatorId}
                        required
                    />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <div className="w3-panel">
                        <FormHelperText className="w3-text-black">Priority</FormHelperText>
                    </div>
                    <InputSelectField
                        name="priority"
                        id="priority"
                        value={form.priority}
                        onChange={handleChange}
                        options={priorityList}
                        errors={errors.priority}
                        required
                    />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <div className="w3-panel">
                        <FormHelperText className="w3-text-black">Estimated time to finish</FormHelperText>
                    </div>
                    <InputSelectField
                        name="due"
                        id="due"
                        value={form.due}
                        onChange={handleChange}
                        options={estimationList}
                        errors={errors.due}
                        required
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} justify={'flex-end'} className="w3-margin-top w3-panel w3-margin-bottom">
                <>
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleClose}

                        >No
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            type="submit"
                            onClick={handleSubmit}
                        >Yes
                        </Button>
                    </Grid>
                </>
            </Grid>
        </div>
    )

}