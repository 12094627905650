import React, { useState } from 'react';
import {
    Grid, Drawer, Divider, List, Button, InputLabel, MenuItem, Select, FormControl
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';

const FilterTickets = ({ filterOn, handleTicketsFilters, leftItems }) => {

    const [filter, setFilter] = useState(filterOn);
    const [drawerOpen, setOpen] = useState(false);

    const handleClearFilter = () => {
        setFilter({ status: '', phase: '' });

    }

    const handleSelectFilter = (event) => {
        const { name, value } = event.target;
        filter[name] = value;
        setFilter(Object.assign({}, filter));
    }

    const triggerFilter = () => {
        handleTicketsFilters(Object.assign({}, filter));
        setOpen(false);
    }

    const toggleDrawer = open => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    const sideList = (
        <div
            className="filter-container"
            role="presentation"
        >
            <h3>Filters</h3>
            <List>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        style={{ minWidth: "300" }}>
                        <InputLabel style={{ background: 'white', padding: '0px 10px' }} id="statusFilterLabel">
                            Select Status
                        </InputLabel>
                        <Select
                            labelId="statusFilterLabel"
                            id="status"
                            name="status"
                            value={filter.status || ''}
                            onChange={handleSelectFilter}

                        >
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="closed">Closed</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        style={{ minWidth: "300" }}>
                        <InputLabel style={{ background: 'white', padding: '0px 10px' }} id="phaseFilterLabel">
                            Select Phase
                        </InputLabel>
                        <Select
                            labelId="phaseFilterLabel"
                            id="phase"
                            name="phase"
                            value={filter.phase || ''}
                            onChange={handleSelectFilter}
                        >
                            <MenuItem value="Tier 1">Tier 1</MenuItem>
                            <MenuItem value="Tier 2">Tier 2</MenuItem>
                            <MenuItem value="Tier 3">Tier 3</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </List>
            <Divider />
            <List>
                <Grid container justify="space-between" alignItems="center">
                    <Button variant="contained"
                        size="small"
                        onClick={handleClearFilter}
                    >
                        Clear
                    </Button>
                    <Button color="primary"
                        variant="contained"
                        size="small"
                        onClick={triggerFilter}
                        className="w3-center">
                        Filter
                    </Button>
                </Grid>
            </List>
        </div>
    );

    return (
        <div>
            {leftItems}
            <IconButton
                className="w3-right cursor-pointer" 
                onClick={toggleDrawer(true)}
            >
                <FilterListIcon />
            </IconButton>
            <Drawer
                anchor="right"
                right="true"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {sideList}
            </Drawer>
        </div>
    );
}



export default FilterTickets;