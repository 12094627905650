import React from "react";
import { connect } from "react-redux";
import {
  FormHelperText,
  Button,
  Grid,
  Card,
  TextareaAutosize,
  Hidden,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Modal,
  Paper,
} from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { Checkmark } from "react-checkmark";
import { InputTextField, InputSelectField } from "../Common/HelperInputs";
import {
  postCreateTicket,
  getSessionInfo,
  postClientUpdateTicket,
} from "../../Redux/actions";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Loader from "../Common/Loader";
import HeroUploadPage from "../../img/FS_Issue_Details.png";
import HeroResolution from "../../img/FS_Resolutions.png";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Rating from "@material-ui/lab/Rating";
import CloseIcon from "@material-ui/icons/Close";
import ModalComponent from "../Common/Modal";
import Lottie from "lottie-react";
import FairSettleAi from "../../img/Animations/fairsettle_ai.json";
import ThankYou from "../../img/Animations/thankyou_done.json";
import Support from "../../img/Animations/help_and_support.json";
import { navigate } from "hookrouter";
import { ToastContainer, toast } from "react-toastify";
import FairsettleLogo from "../../img/fair.png";


class FeedbackPage extends React.Component {
  constructor(args) {
    super(args);

    this.state = {
      errors: {},
      showAppMessage: false,
      message: "",
      showLoader: false,
      clsMessage: null,
      formData: {},
      isFile: false,
      init: false,
      checked: {
        A: false,
        B: false,
        C: false,
        D: false,
      },
      showMore: false,
      showResolution: "show-resolution",
      hideResolution: "hide-resolution",
      showAni: false,
      ai: false,
      selectedValue: "",
      showFinalData: true,
      support: false,
      options: [],
      viewType: "proofUpload",
      disableBtn: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleClearFile = this.handleClearFile.bind(this);
  }

  componentDidMount() {
    const BASE_URL = window.BASE_URL;
    const { id, client } = this.props;
    let product = "";
    fetch(`/api/v1/ticket/get-ticket/${id}`)
      .then((res) => res.json())
      .then((json) => {
        if(json.productExperience === "null"){
          json.productExperience = ""
        }
        if(json.resolution === "null"){
          json.resolution = ""
        }
        this.setState({
          formData : json
        })
        
      });
  }



  handleChange(evt) {
    const { name, value } = evt.target;
    const { formData, errors } = this.state;
    formData[name] = value;
    if (errors[name]) {
      errors[name] = null;
    }
    this.setState({ formData, errors });
  }

  handleFileUpload = (e) => {
    const { formData, errors } = this.state;
    const err = Object.assign({}, errors);
    if (err["attachments"]) {
      err["attachments"] = null;
    }
    formData["attachments"] = e.target.files[0];
    if (e.target.files[0]) {
      this.setState({ formData, errors: err, isFile: true }, () => {
        toast("File uploaded successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      });
    }
  };

  handleClearFile = (e) => {
    const { formData } = this.state;
    formData.attachments = null;
    this.setState({ formData, isFile: false });
  };

  validateData(data) {
    const { errors } = this.state;
    let isValid = true;
    const ignoreList = ["attachments", "type", "description"];

    if (!/^\d{10}$/.test(data.phoneNumber)) {
      isValid = false;
      errors.phoneNumber = "Enter a valid phone number";
    }
    if (
      !/^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        data.emailId
      )
    ) {
      isValid = false;
      errors.emailId = "Enter a valid email";
    }

    Object.keys(data).forEach((key) => {
      if (key !== "deleted_at" && ignoreList.indexOf(key) < 0) {
        if (typeof data[key] === "string") {
          if (!data[key].match(/\w/)) {
            errors[key] = "This field is mandatory";
          }
        }
        if (!data[key]) {
          errors[key] = "This field is mandatory.";
        }
      }
      if (errors[key]) {
        isValid = false;
      }
    });

    if (Object.keys(errors).length > 0 && !isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  handleFormSubmit(evt) {
    evt.preventDefault();
    const { formData } = this.state;
    if (!this.validateData(formData)) {
      return false;
    }
    this.setState({
      showLoader: true,
    });
    let newFormData = new FormData();
    for (let key in formData) {
      newFormData.append(key, formData[key]);
    }
  }

  openFileUpload(e) {
    document.getElementById("proof").click();
  }

  uploadButton(e) {
    const { formData } = this.setState;
    if (formData?.attachments?.name !== "") {
      this.setState({
        isFile: true,
      });
    }
  }

  handleChecked = (event) => {
    this.setState({
      checked: {
        [event.target.name]: event.target.checked,
      },
      selectedValue: event.target.name,
    });

    if (event.target.name === "D" && event.target.checked == true) {
      this.setState({ showMore: true,  });
    } else {
      this.setState({ showMore: false });
    }
  };

  handleClickResolution = (e) => {
    e.preventDefault();
    this.setState({ selectedValue: e.currentTarget.value }, ()=>this.handleContinue(e));
  };

  handleInit = (e) => {
    e.preventDefault();
    const { formData } = this.state;
    this.setState({
      showLoader: true,
      disableBtn: true,
    });
    let newFormData = new FormData();
    for (let key in formData) {
      newFormData.append(key, formData[key]);
    }

    this.props.postClientUpdateTicket(newFormData).then((res) => {
      this.setState({ showLoader: false });
      const resp = res && res.data;
      if (resp && resp.success) {
        this.setState({
          init: true,
          hideResolution: "show-resolution",
          showResolution: "hide-resolution",
          showAni: true,
          ai: true,
          options: resp.ruleOptions,
          viewType: "resolutionPage"
        });
      }
    });
  };

  handleClose = (e) => {
    this.setState({ showAni: false, ai: false });
  };

  handleContinue = (e) => {
    e.preventDefault();
    const { formData, selectedValue } = this.state;
    formData.resolutionOffered = selectedValue;

    this.props.postClientUpdateTicket(formData).then((res) => {
      this.setState({ showLoader: false });
      const resp = res && res.data;
      if (resp && resp.success) {
        this.setState({
          showAni: true,
          ai: false,
          // selectedValue:""
        });
      }
    });
  };

  handleNavigate = () => {
    const { formData } = this.state;
    const Domain_redirect = formData.customer.domain;
    window.location.replace(Domain_redirect);
  };

  handleMatch = (e, val) => {
    e.preventDefault();
    const { formData } = this.state;
    formData.resolutionOffered = val;

    this.props.postClientUpdateTicket(formData).then((res) => {
      this.setState({ showLoader: false });
      const resp = res && res.data;
      if (resp && resp.success) {
        this.setState({
          showAni: false,
          support: true,
        });
      }
    });
      
  };

  render() {
    const {
      formData,
      errors,
      showAppMessage,
      message,
      clsMessage,
      showLoader,
      isFile,
      init,
      checked,
      showMore,
      showResolution,
      hideResolution,
      showAni,
      ai,
      selectedValue,
      showFinalData,
      support,
      options,
      viewType,
      disableBtn
    } = this.state;
    
    console.log('Btn: ', disableBtn );
    const Clogo = formData?.customer?.logo;
    const confirmationList = [
      { val: "Yes", name: "Yes" },
      { val: "No", name: "No" },
    ];
    const ai_animation = (
      <div>
        <Paper className="h7t-ai-modal">
        <div className="w3-margin-top">
          <img style={{width:"30%"}} src={FairsettleLogo} />
          </div>
          <p id="simple-modal-description">
          Please wait! FairSettle.ai is checking options to address your concerns…
          </p>
          <Lottie animationData={FairSettleAi} />
        </Paper>
      </div>
    );
    const thank_animation = (
      <div>
        <Paper className="h7t-ai-modal">
          <div className="w3-margin-top">
          <img style={{width:"30%"}} src={FairsettleLogo} />
          </div>
          <Lottie
            animationData={ThankYou}
            style={{ height: "120px", width: "120px" }}
            loop={true}
          />
          <p className="init-primary-fonts"><b>Thank you for giving us an opportunity to help address your concern.</b></p>
           <p id="simple-modal-description">
            We have noted the option selected by you. Details will be shared via email. Stay tuned!
          </p>
          <Button
            className="h7t-modal-content-btn"
            onClick={this.handleNavigate}
          >
            <b>Okay</b>
          </Button>
        </Paper>
      </div>
    );

    const support_animation = (
      <div>
        <Paper className="h7t-ai-modal">
        <div className="w3-margin-top">
          <img style={{width:"30%"}} src={FairsettleLogo} />
          </div>
          <Lottie
            animationData={ThankYou}
            style={{ height: "120px", width: "120px" }}
            loop={true}
          />
          <p id="simple-modal-description">
          We have noted your expectations. We will circle back to you. Stay tuned!
          </p>
          <Button
            className="h7t-modal-content-btn"
            onClick={this.handleNavigate}
          >
            Okay
          </Button>
        </Paper>
      </div>
    );
    if (ai === true) {
      setTimeout((e) => this.handleClose(e), 3000);
    }

    let viewContent = null;
         
            let leftContent = (
              <div className="w3-padding ">
                  {viewType == "proofUpload" && <img src={HeroUploadPage} className="init-hero-res-img" alt="code" />}
                  {viewType == "resolutionPage" && <img src={HeroResolution} className="init-hero-res-img"alt="code" />}
              </div>
            )

            let rightContent = (
              <div className="w3-padding">
                  {viewType == "proofUpload" && 
                    <div>
                    <div>
                      <img src={Clogo} className="customer-logo" alt="code" />
                    </div>
                    <div className="margin-top-2">
                      <p className="init-card-content">Welcome to FairSettle.ai</p>
                    </div>
                    <div>
                      <p className="init-card-content-desc">
                        You are in the right place!
                      </p>
                      <p className="init-issue-desc">
                       <span className="color-grey">Your Order ID : </span>
                        <span className="color-dark"><b> #{formData?.id}</b></span>
                      </p>
                    </div>
    
                    <p className="init-order-info">
                      Would you share some more details about your concerns?
                    </p>
    
                    <div>
                      <div>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={5}
                          placeholder="Please explain your experience with the product"
                          className="textarea-style"
                          name="productExperience"
                          value={formData?.productExperience}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </div>
    
                      <p className="init-exp">Attach Documents (optional)</p>
    
                      <div style={{ width: "90%" }}>
                        {isFile ? (
                          <div className="uploaded-file">
                            <div style={{fontSize:"12px"}}><b>{formData?.attachments?.name}</b></div>
                            <CloseIcon
                              className="file-upload-cancel"
                              onClick={(e) => this.handleClearFile(e)}
                            />
                          </div>
                        ) : (
                          <div className=" upload-file-align">
                            <button                            
                              onClick={(e) => this.openFileUpload(e)}
                              className="init-file-upload"
                            >
                              {formData?.attachments?.name ? formData?.attachments?.name : "Click to upload documents or additional images, if any "} 
                              </button>
                            <input
                              type="file"
                              id="proof"
                              className="file-choose-btn"
                              accept="image/png,image/jpeg,.pdf"
                              name="attachments"
                              onChange={(e) => this.handleFileUpload(e)}
                            />
                          </div>
                        )}
                      </div>
    
                      <Button
                        disabled={disableBtn}
                        className="init-submit"
                        onClick={(e) => this.handleInit(e)}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                  }

                  {viewType == "resolutionPage" && 
                  <div>
                   <Modal
                    open={this.state.showAni}
                    onClose={(e) => this.handleClose(e)}
                  >
                    {ai ? ai_animation : thank_animation}
                  </Modal>
                  <Modal
                    open={this.state.support}
                    onClose={(e) => this.handleClose(e)}
                  >
                    {support ? support_animation : ""}
                  </Modal>  
                  {showFinalData ? (
                    <div className="w3-margin-top" style={{marginRight:"10%"}}>
                      <div className="w3-margin-top">
                        <p className="init-order-details color-dark" style={{margin:"0px", fontSize:"14px"}}>
                          Thanks for waiting. Based on your feedback, we have
                          curated these options for you. Please select an option
                          of your choice.
                        </p>
                      </div>
                      <div className="resolution-buttons-wrapper">
                        {options?.map((test) => (
                          <Button
                            value={test.name}
                            onClick={(e) => this.handleClickResolution(e)}
                            className="resolution-buttons"
                            variant="outlined"
                            disabled={showMore}
                          >
                            <b>{test.name}</b>
                          </Button>
                        ))}
                      </div>
                      <div>
                        <FormGroup aria-label="position">
                          
                          <FormControlLabel
                            value="4"
                            name="D"
                            control={<Checkbox color="primary" size="small" />}
                            label="None of the above"
                            labelPlacement="None of the above"
                            checked={checked.D}
                            onChange={(e) => this.handleChecked(e)}
                            className="checkbox-name"
                          />
                        </FormGroup>
                      </div>
                      {showMore ? (
                        <div>
                          <p style={{fontSize:"14px"}} className="init-order-details color-dark"  >
                            Looks like the options presented to you were not to
                            your satisfaction. Please help us understand your
                            expectations.
                          </p>
                          
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={4}
                            placeholder="Tell us the solution that you would like to have"
                            className="textarea-style"
                            name="resolution" 
                            value={formData?.resolution}
                            onChange={(e) => this.handleChange(e)}
                            style={{width:"100%"}}
                          />
                          <div className="resolution-items">
                            <Grid container>
                              
                              <Button
                                className="none-of-submit"
                                onClick={(e) => this.handleMatch(e,"none")}
                              >
                                Submit
                              </Button>
                            </Grid>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <p className="init-order-details color-dark">
                          We are pleased to offer the following option to resolve
                          your complaint
                        </p>
                        <div>
                          <p className="init-order-details color-dark">
                            <b>{formData?.resolution}</b>
                          </p>
                        </div>
                        <div>
                          <Button
                            className="init-submit"
                            onClick={(e) => this.handleContinue(e)}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {showMore ? (
                    ""
                  ) : (
                    <div>
                      <Button
                        className="init-submit"
                        onClick={(e) => this.handleContinue(e)}
                        disabled
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </div>}
                  

              </div>
            )

    return (
      <>
      
        <Card className="init-card" variant="outlined">
          <Grid container >
            <Grid item lg={7} className={`code-align`}>
              <Hidden only={["md", "sm", "xs"]}>
                {leftContent}
              </Hidden>
            </Grid>
            <Grid item lg={5}>
              {rightContent}
            </Grid>
          </Grid>
        </Card>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {
  postClientUpdateTicket,
  postCreateTicket,
  getSessionInfo,
})(FeedbackPage);
