import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard  from './Dashboard';
import { Can } from '../../Ability';
import { navigate } from 'hookrouter';

const IndexDashboard = () => {
    const state: any = useSelector(state => state);
    const { currentUserRoles } = state;
    if (!currentUserRoles || currentUserRoles.isFetching) {
        return (
            <div className="w3-center">
                <CircularProgress />
            </div>
        );
    }
    
    
    if (currentUserRoles && currentUserRoles.data && currentUserRoles.data.success && currentUserRoles.data.data) {
        const userRole = currentUserRoles.data.data;
        let customerId;
        const checkCustomerSupport = userRole.findIndex((check: any) => {
            if (check.roles && check.roles.type === 'customer_support') {
                customerId = check.customer.id;
                return true;
            }
        });
        if (checkCustomerSupport !== -1) {
            navigate(`/enquiries/${customerId}/user`);
        }
    }
    return <Can I="view" a="Dashboard">
        <Dashboard />
    </Can>
};

export default IndexDashboard;
