import React from 'react';
import Login from '../Components/Auth/Login';
import Register from '../Components/Auth/Register';
import ForgotPassword from '../Components/Auth/ForgotPassword';
import ResetPassword from '../Components/Auth/ResetPassword'; 
import Popup from '../Components/Feedback/Popup';
import Invite from '../Components/Auth/Invite';
import Feedback from '../Components/Feedback';
import TopBar from '../Components/Common/TopBar';
import ClosureLink from '../Components/Support/ClosureLink';
import RuleLink from '../Components/Support/RuleLink';
import { render } from 'react-dom';
import { A, useRoutes, usePath } from 'hookrouter';

const HeaderLayout = ({ children, isLogo = false , id}: {children:any, isLogo?: boolean ,id?:any}) => {
// const HeaderLayout = ({ children, isLogo = false, client }: {children:any, isLogo?: boolean, client?:any}) => {
    
    return <div className="app-landing-page"  >
        {/* <TopBar isLogo = {isLogo} client={client}/> */}
        <TopBar isLogo = {isLogo} id={id}/>
        {children}
    </div>
}


const routes = {
    "/": () => <HeaderLayout><Login /></HeaderLayout>,
    "/login": () => <HeaderLayout><Login /></HeaderLayout>,
    "/register": () => <HeaderLayout><Register /></HeaderLayout>,
    "/feedback/:client/:id": ({ client,id }: any) => <HeaderLayout><Feedback client={client} id={id}/></HeaderLayout>,
    "/popup/:client/:id": ({ client, id }: any) => <HeaderLayout isLogo={true} id={id}><Popup client={client}  id={id}/></HeaderLayout>,
    "/forgot-password": () => <HeaderLayout><ForgotPassword /></HeaderLayout>,
    "/reset-password/:token": ({ token }: any) => <HeaderLayout><ResetPassword token={token} /></HeaderLayout >,
    "/invite/:token": ({ token }: any) => <HeaderLayout> <Invite token={token} /></HeaderLayout >,
    "/closure-link/:token": ({ token }: any) => <HeaderLayout><ClosureLink token={token} /></HeaderLayout>,
    "/rule-link/:token/:option": ({ token,option}: any) => <HeaderLayout><RuleLink token={token} option={option}/></HeaderLayout>,

};

const LayoutRoutes = () => {
    const content = useRoutes(routes) || <Login />;
    const path = usePath();
    return <div>
        {content}
        {!(path.split("/").includes('popup')) &&
            <div className="app-footer">
            <div className="copy-right">
            © 2022 FairSettle.ai & its affiliates
            </div>
        </div>
        }
        
    </div>

}

export default LayoutRoutes;
