import React from 'react';
import moment from 'moment';
import {
    Grid, Typography, Box, Divider,
} from '@material-ui/core';

export const TicketMessage = (props) => {
    const { title, description, actor, time, email, type } = props;
    const formatEmail = type === "msg:incoming_email" && email && email.html ? email.html.replace(/<\s*(\w+).*?>/gi, '<$1>'): type === "msg:rule_email" && email ? email.replace(/<\s*(\w+).*?>/gi, '<$1>'): email;
    
    return (
        <React.Fragment>
            <Box className="w3-padding message-container">
                <Grid container justify="space-between">
                    <Grid item> <Typography variant="subtitle1" component="b" fontWeight="fontWeightBold" >{actor}</Typography></Grid>
                    <Grid item> <Typography>{moment(time).fromNow()}</Typography></Grid>
                </Grid>
                <Typography className="w3-margin-top" display="block" component="b">{title}</Typography>
                <Typography className="w3-margin-top wrapText">{description}</Typography>
                
                {email && <div className="w3-border w3-padding">
                    <div className="w3-margin-top">
                        <div dangerouslySetInnerHTML={{__html:formatEmail}} />
                        <hr/>
                        {email && email.attachments && email.attachments.length >0 && <b>Attachments</b>}
                        {email && email.attachments && email.attachments.length >0 && email.attachments.map((item, idx) => <div className="w3-padding-small">
                        <React.Fragment key={idx}>
                            <a href={`/api/v1/ticket/get-attachment?key=${item.content}`} className="w3-border-bottom" target="_blank" style={{textDecoration:'none'}}>{item.filename}</a>
                        </React.Fragment>
                        </div>)}
                          
                    </div>
                </div>}
            </Box>
            <Divider className="w3-margin-bottom w3-margin-top" />
        </React.Fragment>
    )
}

export class TicketStatus extends React.Component {

    render() {
        const { ticketDetails, type } = this.props;
        let content = [];

        
        if (ticketDetails && ticketDetails.ticketTimeline) {
            ticketDetails.ticketTimeline.forEach((timeline,idx) => {
                content.push(
                    <React.Fragment key={idx}>
                        <TicketMessage
                            title={timeline.information}
                            description={timeline.data ? timeline.data.message : ''}
                            actor={timeline.actor}
                            time={timeline.createdAt}
                            email={timeline && timeline.data && timeline.data.email ? timeline.data.email : null}
                            type ={timeline && timeline.type ? timeline.type: null}
                        />
                    </React.Fragment>
                )
            });
        }

        return (
            <div className={type ? '' : 'scroll'}>
                {content}
            </div>
        )
    }
}
