import React, { useEffect, useState } from 'react';

import { AppBar, Toolbar, Typography, } from '@material-ui/core';
import fairSettleLogo from '../../img/fair.png';
import { render } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTicketById } from '../../Redux/actions';





const TopBar = ({ isLogo, id }: { isLogo?: boolean, id?: any }) => {

    const [clientLogo, setClientLogo] = useState();
    const dispatch: any = useDispatch();


    useEffect(() => {

        dispatch(getTicketById(id)).then((resp: any) => {
            const res = resp && resp.data;
            setClientLogo(res?.customer?.logo);

        });

    }, []);


    return (
        <AppBar position="fixed" className="appBar">
            <Toolbar>
                {/* <Typography variant="h5"> */}
                <div style={{ display: "flex", justifyContent: isLogo ? "space-between" : "flex-start", width: "100%" }}>
                    {isLogo && <img src={clientLogo} style={{ height: "70px", padding: "8px" }} alt=" " />}
                    {!isLogo && <img src={fairSettleLogo} style={{ height: "70px" }} alt=" " /> }
                </div>
                {/* </Typography> */}
            </Toolbar>
        </AppBar>
    )
};

export default TopBar;
