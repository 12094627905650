 export const zoomCallTypes = {
    CALL_SCHEDULED: 'call_scheduled',
    CALL_STARTED: 'call_started',
    START_CALL: 'start_call',
    CALL_COMPLETED: 'call_completed',
    CALL_ERROR: 'call_error',
    CALL_ENDED: 'call_ended',
};

export const zoomCallStatusText = {
    [zoomCallTypes.CALL_SCHEDULED]: 'Call Scheduled',
    [zoomCallTypes.CALL_STARTED]: 'Call Started',
    [zoomCallTypes.START_CALL]: 'Start Call',
    [zoomCallTypes.CALL_COMPLETED]: 'Call Completed',
    [zoomCallTypes.CALL_ERROR]: 'Call Error',
    [zoomCallTypes.CALL_ENDED]: 'Call Ended',
}

export const constants = {
    zoomCallStatusText,
}