import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardHeader, CardContent, CardActions, Button, CircularProgress, ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core';
import { TextInputField, ErrorHelperText } from '../Common/HelperInputFields';
import { useDispatch } from 'react-redux';
import { postAcceptInvite, getValidateTokenStatus } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog'; 
import TopBar from '../Common/TopBar';
import { makeStyles } from '@material-ui/core/styles';

import { A, navigate } from 'hookrouter';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    formTop: {
        marginTop: '100px',
    },
    cardTitle: {
        color: '#303f9f',
    }
}));

const InviteMsgCard = (props: any) => {
    const { cardMsg, cardTitle, showActions } = props;
    const classes = useStyles();
    return (
        <Card>
            <Grid container alignItems="center" direction="column">
                <CardHeader className={classes.cardTitle} title={cardTitle} />
                <CardContent>
                    <Typography component="h5">
                        {cardMsg}
                    </Typography>
                </CardContent>
                {showActions ? (
                    <CardActions className="padding16">
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => navigate('/login')}
                        >Login
                        </Button>
                    </CardActions>
                ) : null}
            </Grid>
        </Card>
    )
}

const Invite = (props: any) => {
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const initForm: any = {
        password: '',
        confirm: ''
    };
    const initErr: any = {};
    const [form, setForm] = useState(initForm);
    const [errors, setErrors] = useState(initErr);
    const [alertMessage, setAlertMessage] = useState(null);
    const [inviteStatus, setInviteStatus] = useState(null);
    let displayCard: any = null;
    const [passReg, setPassReg] = useState(false);

    useEffect(() => {
        const fetchTokenValidationStatus = () => {
            dispatch(getValidateTokenStatus(props.token))
            .then((resp: any) => {
                const res = resp && resp.data; 
                setInviteStatus(res.data);
            });
        };
        fetchTokenValidationStatus();
    }, [1]);

    const handleChange = (e: any) => {
        const { value, name } = e.target;
        const fieldValue = Object.assign({}, form);
        const errorField = Object.assign({}, errors);
        if (errorField[name]) {
            errorField[name] = null;
            setErrors(errorField);
        }
        fieldValue[name] = value;
        setPassReg(false);
        setForm(fieldValue);
    }

    const validateData = () => {
        let hasError = false;
        const err = Object.assign({}, errors);
        const regex = /^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*]).{8,}$/;


        if (form.password !== form.confirm) {
            hasError = true;
            err.confirm = "Password and confirm password must be same."
        }

        if (form.password === form.confirm) {
            if (!regex.test(form.password)) {
                hasError = true;
                err.password = 'Password Doesnt meet the requirements';
                setPassReg(true);
            }
        }

        Object.keys(form).forEach((key) => {
            if (!form[key]) {
                hasError = true;
                err[key] = 'This field is required';
            }
        });
        if (hasError) {
            setErrors(err);
            return false;
        }
        return form;
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const valid = validateData();
        if (valid) {
            valid.token = props.token;
            dispatch(postAcceptInvite(valid)).then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    //setAlertMessage('Password set successfully. Login now...!')
                } else if (res && res.data) {
                    setErrors(res.data);
                } else {
                    //setAlertMessage('Something went wrong. Try again...!')
                }
            });
        }
    } 
    
    if (inviteStatus == null || inviteStatus == undefined || !inviteStatus) {
        return ( <div className="textMarginCenter">
                <CircularProgress />
            </div>);
    }

    if (inviteStatus && inviteStatus.status === 'Invited') {
        displayCard = (
            <Card>
                <form onSubmit={(e) => { handleSubmit(e) }}>
                    <CardHeader title="Please set a password to Join" />
                    <CardContent>
                        <TextInputField
                            type="password"
                            name="password"
                            placeholder="New Password"
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            errors={errors.password}
                        />
                        <TextInputField
                            type="password"
                            name="confirm"
                            placeholder="Confirm Password"
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            errors={errors.confirm}
                        />
                        <ErrorHelperText error={errors.token} />
                    </CardContent>

                    {
                        passReg &&
                        <CardContent>
                            <div className={classes.root}>
                                <ExpansionPanel>
                                    <ExpansionPanelDetails>
                                        <Typography className="w3-text-red">
                                            <li> Minimum password length 8</li>
                                            <li>Require at least one digit</li>
                                            <li>Require at least one upper case</li>
                                            <li> Require at least one lower case letter</li>
                                            <li>Require at least one symbol</li>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </CardContent>
                    }


                    <CardActions className="padding16">
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            style={{ marginLeft: 'auto' }}
                            onClick={(e) => handleSubmit(e)}
                        >Join
                    </Button>
                    </CardActions>
                </form>
            </Card>
        )
    } else if (inviteStatus.status === 'Joined') {
        displayCard = (
            <InviteMsgCard
                cardTitle={'Invitation already accepted'}
                cardMsg={'You have already accepted the invitation. Please login to continue.'}
                showActions={true}
            />
        )
    } else if (inviteStatus.status === 'Expired') {
        displayCard = (
            <InviteMsgCard
                cardTitle={'Invitation expired'}
                cardMsg={'Invitation got expired, for further details please contact admin'}
            />
        )
    } else  {
        displayCard = (
            <InviteMsgCard
                cardTitle={'Invitation Invalid'}
                cardMsg={`Invitation got expired, for further details please contact admin ${inviteStatus.message}`}
            />
        )
    }


    return (
        <div>
            <TopBar />
            <Grid container className={classes.formTop} >
                <Grid item xs={6} className="marginAuto marginTop50">
                    {displayCard}
                </Grid>
            </Grid>
            {alertMessage &&  (
                    <AlertDialog
                        handleClose={() => {
                            setAlertMessage(null)
                            navigate('/login')
                        }}
                        message={alertMessage}
                    />
                ) }
        </div>
    );
}

export default Invite;