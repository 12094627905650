import queryString from 'querystring';
import axios from 'axios';
import api from './api';

const requestMap: any = api;
export const actions = {
    FETCH_REQUEST: 'FETCH_REQUEST',
    FETCH_REQUEST_SUCCESS: 'FETCH_REQUEST_SUCCESS',
    FETCH_REQUEST_ERROR: 'FETCH_REQUEST_ERROR',
    SET_DATA: 'SET_DATA',
    APP_MESSAGE: 'APP_MESSAGE',
};

const config: any = {
    headers: {}
};

if (localStorage.getItem('fairsettle_access_token')) {
    config.headers['Authorization'] = 'bearer ' + localStorage.getItem('fairsettle_access_token');
}

const axiosApiCall: any = axios.create(config);

export const setStoreData = (key: string, value: any) => {
    return {
        type: actions.SET_DATA,
        key,
        value
    };
};

export const fetchDataRequest = (key: string) => {
    return {
        type: actions.FETCH_REQUEST,
        key
    };
};

export const fetchDataRequestError = (key: string, error: any) => {
    return {
        type: actions.FETCH_REQUEST_ERROR,
        key,
        error
    };
};


export const fetchResponseSuccess = (key: string, data: any) => {
    return {
        type: actions.FETCH_REQUEST_SUCCESS,
        key,
        data
    };
};

export const fireRequest = (
    key: string, path: any = [], params: any = {}
) => {
    return (dispatch: any) => {
        const request = Object.assign({}, requestMap[key]);
        if (path.length > 0) {
            request.path += '/' + path.join('/');
        }

        if (request.method === undefined || request.method == 'GET') {
            request.method = 'GET';
            const qs = queryString.stringify(params);
            if (qs !== '') {
                request.path += `?${qs}`;
            }
        }

        dispatch(fetchDataRequest(key));
        return axiosApiCall[request.method.toLowerCase()](request.path, params)
            .then((response: any) => {
                dispatch(fetchResponseSuccess(key, response.data));
                return response;
            })
            .catch((error: any) => {
                dispatch(fetchDataRequestError(key, error));
                return error.response;
            });
    };
};

// auth
export const postCreateUser = (form: object) => {
    return fireRequest('createUser', [], form);
};

export const getCurrentUser = () => {
    return fireRequest('currentUser');
};

export const changeAdminStatus = (userId: number, type: string) => {
    return fireRequest('updateAdminStatus', [], {
        userId,
        type
    });
};

export const getRoleUsers = (roleName: string) => {
    return fireRequest('getRoleUsers', [], {
        role: roleName,
        type: 'nothing'
    });
};


export const getCurrentUserRoles = () => {
    return fireRequest('currentUserRoles');
};

export const postRegister = (form: object) => {
    return fireRequest('register', [], form);
};

export const postLogin = (form: object) => {
    return fireRequest('login', [], form);
};

export const postForgotPassword = (form: object) => {
    return fireRequest('forgotPassword', [], form);
};

export const postResetPassword = (form: object) => {
    return fireRequest('resetPassword', [], form);
};

export const getAllUsers = () => {
    return fireRequest('usersList');
};

export const changeUserPassword = (form: object) => {
    return fireRequest('changeUserPassword', [], form);
};
export const changeUserName = (form: object) => {
    return fireRequest('changeUserName', [], form);
};

//invite

export const postAcceptInvite = (form: object) => {
    return fireRequest('acceptInvite', [], form);
};

export const getValidateTokenStatus = (token: string) => {
    return fireRequest('validateToken', [token], {});
};

//Enquiries
export const postCreateEnquiry = (form: object) => {
    return fireRequest('createEnquiry', [], form);
};

export const getAllEnquiryList = (paginate: object) => {
    return fireRequest('enquiryList', [], paginate);
};

export const putUpdateEnquiry = (form: object, id: number) => {
    return fireRequest('updateEnquiry', [id], form);
};

//Dashboard

export const getDashboardStats = () => {
    return fireRequest('dashboardStats');
};

export const getDashEnquiryStats = () => {
    return fireRequest('enquiryStats');
};

export const PostSheetData = () => {
    return fireRequest('sheetdata', []);
};

export const getCustomerList = () => {
    return fireRequest('customerList')
};

// Meta
export const getAllMeta = () => {
    return fireRequest('getAllMeta');
};

export const addMeta = (form: object) => {
    return fireRequest('addMeta', [], form);
};

export const getMetaByName = () => {
    return fireRequest('getMetaByName', [], {});
};

export const deleteMeta = (id: number) => {
    return fireRequest('deleteMeta', [], { id });
};

export const getAllTerms = (id: number) => {
    return fireRequest('getAllTerms', [], { id });
};

export const addTerm = (form: object) => {
    return fireRequest('addTerm', [], { form });
};

export const getTermById = (id: number) => {
    return fireRequest('getTermById', [], { id });
};

export const updateTerm = (form: object) => {
    return fireRequest('updateTerm', [], { form });
};

export const deleteTerm = (id: number) => {
    return fireRequest('deleteTerm', [id], {});
};

export const findTerm = (search: string) => {
    return fireRequest('findTerm', [], { search });
};

export const feedback = (form: object) => {
    return fireRequest('feedback', [], form);
};

export const approval = (status: string, id: number) => {
    return fireRequest('approval', [], { status, id });
};

export const getVersion = () => {
    return fireRequest('versionNumber', [], {});
};

export const postResult = (resultData: object) => {
    return fireRequest('resultInfo', [], resultData);
};

export const getFirstUser = () => {
    return fireRequest('firstUser', [], {});
};

export const logout = () => {
    return fireRequest('logout', [], {});
}

export const setAppMessage = (type: any, text: any) => {
    return {
        type: actions.APP_MESSAGE,
        key: 'appMessage',
        value: {
            type,
            text,
        },
    };
};


export const modalAction = (key: any, value: any) => {
    return {
        type: 'SET_UI_MODAL',
        key,
        value,
    };
};

//create-ticket

export const postCreateTicket = (form: any) => {
    return fireRequest('createTicket', [], form);
};

//ticket-list
export const getAllTickets = (clientId: number, type = 'user',paginate: object = {}, data = {
    status: '',
    phase: ''
}) => {
    return axiosApiCall.get(`/api/v1/ticket/all-tickets/${clientId}`, {
        params: Object.assign({ type }, data,paginate)
    })
};

//ticket-details
export const getTicketById = (id: string) => {
    return fireRequest('ticketById', [id], {});
};

export const getRatingTrigger = () => {
    return fireRequest('ratingTrigger', [], {});
};

export const postUpdateTicket = (form: any) => {
    return fireRequest('updateTicket', [], form);
}

export const postClientUpdateTicket = (form: any) => {
    return fireRequest('clientTicketUpdate', [], form);
}

export const getAlldashboardTickets = () => {
    return fireRequest('allDashboardTickets', [], {});
};

export const getClosureEmailTime = () => {
    return fireRequest('closureEmailTime', [], {});
};

export const getCustomerDetails = (clientId: number) => {
    return axiosApiCall.get(`/api/v1/customer/customer-details/${clientId}`);
};

export const getUploadedFile = (filename: string) => {
    return fireRequest('uploadedFile', [filename], {});
}

export const postStoreSessionInfo = (form: any) => {
    return fireRequest('storeSessionInfo', [], form);
}

export const getSessionInfo = (token: number) => {
    return fireRequest('sessionInfo', [token], {});
}

export const zoomAuthenticate = (params: any) => {
    return fireRequest('zoomAuth', [], params);
}

export const fetchZoomUserDetails = () => {
    const params: any = {
        zoom_access_token: localStorage.getItem('zoom_access_token')
    }
    return fireRequest('zoomUserDetails', [], params);
}

export const postCreateZoomMeeting = (userId: string, meetingParams: any) => {
    const params: any = {
        userId,
        meetingParams,
        zoom_access_token: localStorage.getItem('zoom_access_token'),
    }
    return fireRequest('zoomMeetingDetails', [], params);
}

export const getMediatorsList = () => {
    return fireRequest('mediatiorsList', [], {});
}

export const postUserUpdateTicket = (form: any) => {
    return fireRequest('userUpdateTicket', [], form);
}

export const getLogoBasedOnRole = () => {
    return fireRequest('logoBasedOnRole', [], {});
};

export const updateTimeline = (form: any) => {
    return fireRequest('updateTimeline', [], form);
};

export const setZoomCallStatus = (ticketId: number) => {
    return fireRequest('zoomCallStatus', [], ticketId);
}