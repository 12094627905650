import React from 'react';
import { Paper, Typography, Grid, Box } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab';
import {
    PersonRounded, EmailRounded, EventRounded, CallRounded,
    RecordVoiceOverRounded, ScheduleRounded, DonutLargeRounded,
    SchoolRounded, Email
} from '@material-ui/icons';
import moment from 'moment';

export const Timelines = ({ ticketDetails }) => {
    const icons = {
        'msg:issue_created': <PersonRounded />,
        'system_email': <EmailRounded />,
        'msg:user_update_yes': <PersonRounded />,
        'user_update_no': <PersonRounded />,
        'system_update': <DonutLargeRounded />,
        'msg:incoming_email': <Email />,
        'msg:rule_email': <DonutLargeRounded />,
        'call_scheduled': <EventRounded />,
        'call_completed': <CallRounded />,
        'msg:support_updated': <RecordVoiceOverRounded />,
        'msg:mediator_update': <ScheduleRounded />,
        'mediator_assigned': <SchoolRounded />,
    }
    return <Paper className="w3-margin-top" >
        <Grid container  >
            <Grid item md={9} className="timeline-container" style={{minHeight:"450px"}}>
                <Timeline >
                    {(
                        ticketDetails && ticketDetails.ticketTimeline && ticketDetails.ticketTimeline.map((ticket, idx) => {
                            if (icons[ticket.type]) {
                                return (
                                    <TimelineItem key={ticket.id}>
                                        <TimelineSeparator>
                                            <TimelineDot style={ticket.type === "user_update_no" ? { backgroundColor: "#cc0808" } : ticket.type === "user_update_yes" ? { backgroundColor: "#22a616" } : { backgroundColor: "#173d58" }}>
                                                {icons[ticket.type]}
                                            </TimelineDot>
                                            {idx === ticketDetails.ticketTimeline.length - 1 ? null : <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Box component="div" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" flex={0.5} style={{ marginTop: "8px" }}>
                                                <Grid container >
                                                    <Grid item md={5} >
                                                        <Typography color="textSecondary" align="left">
                                                            {moment(ticket.actionAt ? ticket.actionAt : '-').format('DD-MM-YYYY hh:mm:ss')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={7}>
                                                        <Typography component="b">
                                                            {ticket.information ? ticket.information : '-'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                )
                            }

                        })
                    )}
                </Timeline>
            </Grid>
        </Grid>
    </Paper>
}