
import React, { useState } from 'react';
import {
    Button, Grid, Typography, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Chip
} from '@material-ui/core';
import { TicketMessage } from './TicketStatus';
import moment from 'moment';
import Compose from './Compose';
import {Can} from '../../Ability';
import Ticket from './Ticket.dao';
import DoneIcon from '@material-ui/icons/Done';
import axios from 'axios';

export const Messages = ({ ticketDetails, postUpdateTicket, callDetails, resetCallDetails }) => {

    const [currentMessage, setCurrentMessage] = useState(null);
    const [showCompose, setShowCompose] = useState(false);

    const handleMessage = (msg) => {
        setShowCompose(false);
        setCurrentMessage(msg);
        if(msg && msg.data && !msg.data.mediatorRead){
            msg.data.mediatorRead = true;
            axios.post('/api/v1/ticket/update-timeline',msg).then((resp) => {
                console.log('resp',resp);
            });
        }
    }

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
      };

    return < Paper className="w3-margin-top" >
        <Grid container item md={12}>
            <Grid item md={4} style={{ borderRight: "solid #ccc 1px" }}>
                <Grid container direction="row" alignItems="center" justify="space-between" className="w3-padding">
                    <Grid item>
                        <h2>Messages</h2>
                    </Grid>
                    <Grid>
                        <Can I="compose" this={new Ticket(ticketDetails)}>
                            <Button
                                variant="contained" 
                                color="primary"
                                onClick={() => setShowCompose(true)}
                            >Compose</Button>
                        </Can>
                    </Grid>
                </Grid>
                <Divider className="w3-margin-bottom w3-margin-top" /> 
                <List style={{height:"60vh",overflow:'auto'}}>
                    {ticketDetails && ticketDetails.ticketTimeline && ticketDetails.ticketTimeline
                        .filter(timeline => timeline && timeline.type.includes('msg'))
                        .map((msg, idx) => (
                        <div className={(msg && msg.type === "msg:incoming_email" && msg.data && !msg.data.mediatorRead) ? '' : 'gmail-bg'}>
                        <React.Fragment key={idx}>
                            {msg && msg.data && msg.data.resolution ?
                            <div className ="w3-padding">
                            <Chip
                                variant="outlined"
                                size="small"
                                //icon={<FaceIcon />}
                                label="Resolution Offered"
                                clickable
                                color="primary"
                                onDelete={handleDelete}
                                deleteIcon={<DoneIcon />}
                            />
                            </div>: ''}
                            <ListItem key={msg.id} alignItems="flex-start" className={(currentMessage && msg.id === currentMessage.id)
                                ? 'w3-leftbar w3-border-blue' : 'w3-leftbar w3-border-white' } onClick={() => handleMessage(msg)}>
                                <ListItemAvatar>
                                    <Avatar src={msg.senderPic} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography component="b"
                                        variant="body1"
                                        className={(msg && msg.type === "msg:incoming_email" && msg.data && !msg.data.mediatorRead) ? 'font-weight-900 ' : ''} > {msg.actor}</Typography>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body1"
                                                style={{ display: 'inline' }}
                                                color="textPrimary"
                                            >
                                                {msg.information}
                                            </Typography>
                                            <br />
                                            <Typography
                                                component="span"
                                                variant="body1"
                                                style={{ display: 'inline' }}
                                                color="textPrimary"
                                            >
                                                {moment(msg.createdAt).fromNow()}
                                            </Typography>
                                            <br />
                                            {msg && msg.data && msg.data.message && <Typography component="span" variant="body1" noWrap>
                                                {msg.data.message}
                                            </Typography> 
                                            }
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                        </div>
                    ))}
                </List>
            </Grid>
            <Grid item md={8} className="w3-padding-64">
                {currentMessage   && <TicketMessage
                    actor={currentMessage.actor}
                    time={currentMessage.createdAt}
                    title={currentMessage.information}
                    email={currentMessage.data ? currentMessage.data.email : null}
                    description={currentMessage.data ? currentMessage.data.message : ''}
                    type ={currentMessage.type ? currentMessage.type: null}
                />}
                {(showCompose || callDetails) && (
                    <Compose
                        type='messages'
                        ticketDetails={ticketDetails}
                        postUpdateTicket={postUpdateTicket}
                        callDetails={callDetails}
                        resetCallDetails={resetCallDetails}
                    />
                )}
            </Grid>

        </Grid>
    </Paper >
}