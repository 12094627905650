import React from "react";
import { Rating } from "@material-ui/lab";
import { connect } from "react-redux";
import { postClientUpdateTicket } from "../../Redux/actions";
import Lottie from "lottie-react";
import ThankYou from "../../img/Animations/thankyou_done.json";
import SadEmoji from "../../img/Animations/sad_emoji.json";
import OneStar from "../../img/Animations/one_star.json";
import TwoStar from "../../img/Animations/two_star.json";
import ThreeStar from "../../img/Animations/three_star.json";
import FourStar from "../../img/Animations/four_star.json";
import FiveStar from "../../img/Animations/five_star.json";
import ThumbNail from "../../img/Animations/FS_Rating Thumbnail.json";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";

import fairSettleLogo from "../../img/fair.png";
import rating_thumbnail from "../../img/rating_thumbnail.png";

import {
  Container,
  Grid,
  Button,
  Card,
  TextareaAutosize,
  Hidden,
  Modal,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Checkmark } from "react-checkmark";
import { InputTextField } from "../Common/HelperInputs";
import Header from "./AppHeader";
import {
  getRatingTrigger,
  getCustomerList,
  postStoreSessionInfo,
} from "../../Redux/actions";
import { navigate } from "hookrouter";
import { ErrorHelperText } from "../Common/HelperInputFields";
import rating_img from "../../img/FS_Macys_Rating.png";
import sad_exp from "../../img/sad1.png";
import { id, is } from "date-fns/locale";

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        rating: 0,
        productExperience: "",
      },
      errors: {},
      setOpen: false,
      rating_trigger: "",
      customersList: [],
      showAppMessage: false,
      checked: false,
      emojiValue: 0,
      modalOpen: false,
      clientDetails: [],
      justGiveFeed: true,
    };
    this.openCustomerModal = this.openCustomerModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const BASE_URL = window.BASE_URL;
    const { id } = this.props;
    fetch(`/api/v1/ticket/get-ticket/${id}`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          clientDetails: data,
        });
      });

    this.props.getRatingTrigger().then((rating) => {
      if (rating && rating.data) {
        this.setState({
          rating_trigger: rating.data.length
            ? rating.data[0].config.value
            : "3",
        });
      }
    });
    this.props.getCustomerList().then((res) => {
      if (res && res.data) {
        this.setState({
          customersList: res.data,
        });
      }
    });
  }

  handleChange(evt, newValue) {
    const { name, value } = evt.target;
    const { formData, errors } = this.state;
    if (newValue) {
      this.setState({
        emojiValue: newValue,
      });
    }

    if (name === "rating") {
      formData[name] = parseInt(value);
    } else {
      formData[name] = value;
    }
    if (errors[name]) {
      errors[name] = null;
    }
    this.setState({ formData, errors });
  }

  openCustomerModal() {
    const { rating_trigger, formData, clientDetails } = this.state;
    const { client, id } = this.props;

    formData.actionToken = clientDetails.actionToken;
    this.props.postClientUpdateTicket(formData).then((resp) => {
      const response = resp && resp.data;
      if (response && response.success) {
        if (formData.rating <= rating_trigger) {
          navigate(`/feedback/${client}/${id}`);
        }
      }
    });
  }

  myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  validateData(data) {
    const { errors } = this.state;
    let isValid = true;
    const ignoreList = ["productExperience"];

    Object.keys(data).forEach((key) => {
      if (key !== "deleted_at" && ignoreList.indexOf(key) < 0) {
        if (typeof data[key] === "string") {
          if (!data[key].match(/\w/)) {
            errors[key] = "This field is mandatory";
          }
        }
        if (typeof data[key] === "number" && data[key] === 0) {
          errors[key] = "This field is mandatory";
        }
        if (!data[key]) {
          errors[key] = "This field is mandatory.";
        }
        // if (key === "rating") {
        //   errors[key] = "Please provide Your Feedback";
        // }
      }
      if (errors[key]) {
        isValid = false;
      }
    });

    if (Object.keys(errors).length > 0 && !isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  handleSubmit() {
    const { rating_trigger, formData } = this.state;
    if (!this.validateData(formData)) {
      return false;
    }
    if (formData.rating <= rating_trigger) {
      this.setState({
        setOpen: true,
      });
    } else if (formData.rating > rating_trigger) {
      this.setState({
        modalOpen: true,
        showAppMessage: true,
        message: "Submited Successfully",
      });
    }
  }

  handleClose = () => {
    this.setState({
      setOpen: false,
    });
  };

  // handleClose = () => {
  //   this.setState({
  //     setOpen: false,
  //   });
  // };
  handleModalOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleOptionChange() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  handleJustGiveFeed = () => {
    this.setState({
      justGiveFeed: !this.state.justGiveFeed,
    });
  };

  handleNavigate = () => {
    const { clientDetails } = this.state;
    const Domain_redirect = clientDetails.customer.domain;
    window.location.replace(Domain_redirect);
  };

  render() {
    const {
      formData,
      errors,
      customersList,
      showAppMessage,
      clientDetails,
      emojiValue,
    } = this.state;
    const hidden = this.state.checked ? "" : "w3-hide";
    const { client } = this.props;
    const emojiMap = {
      0: (
        <div>
          <img src={rating_thumbnail} style={{ width: "30%" }} />
        </div>
      ),
      1: (
        <div>
          <Lottie
            animationData={OneStar}
            style={{ width: "30%", margin: "auto" }}
          />
          <h3 className="emoji-caption">VERY BAD!</h3>
        </div>
      ),
      2: (
        <div>
          <Lottie
            animationData={TwoStar}
            style={{ width: "30%", margin: "auto" }}
          />
          <h3 className="emoji-caption">BAD!</h3>
        </div>
      ),
      3: (
        <div>
          <Lottie
            animationData={ThreeStar}
            style={{ width: "30%", margin: "auto" }}
          />
          <h3 className="emoji-caption">AVERAGE!</h3>
        </div>
      ),
      4: (
        <div>
          <Lottie
            animationData={FourStar}
            style={{ width: "30%", margin: "auto" }}
          />
          <h3 className="emoji-caption">GOOD!</h3>
        </div>
      ),
      5: (
        <div>
          <Lottie
            animationData={FiveStar}
            style={{ width: "30%", margin: "auto" }}
          />
          <h3 className="emoji-caption">AWESOME!</h3>
        </div>
      ),
    };
    let clientName = null;
    if (customersList.length > 0) {
      const item = customersList.find((item) => item.key === client);
      if (item) {
        clientName = item.name;
      }
    }
    return (
      <div>
        <Card className="init-card" variant="outlined">
          <Grid container className="init-container">
            <Grid item lg={7}>
              <Hidden only={["md", "sm", "xs"]}>
                <div className="init-hero-img-wrapper">
                  <img src={rating_img} className="init-hero-img" alt="code" />
                </div>
              </Hidden>
            </Grid>
            <Grid item lg={5} className="init-right-content-wrapper">
              <div></div>
              <div>
                <p className="init-issue-details">Your opinion matters</p>
              </div>
              <div style={{ paddingRight: "5px" }}>
                <p className="init-card-content-desc fair-common-margin-top">
                  We’d love to know how everything worked out for you. Please
                  take a moment to review your most recent shopping experience.
                </p>
              </div>
              <div>
                <p className="init-order-details">
                  <span className="color-grey">Your Order ID:</span>{" "}
                  <span className="color-dark" /> 
                  <b> #{clientDetails?.product}</b>
                </p>
              </div>

              <div className="w3-center rating-star-wrapper">
                {emojiMap[this.state.emojiValue]}
                <div>
                  <div className="w3-center">
                    <Rating
                      className="rating-size"
                      style={{ display: "flex" }}
                      name="rating"
                      id="rating"
                      value={formData.rating ? formData.rating : 0}
                      defaultValue={5}
                      size="large"
                      onChange={(e, newValue) => this.handleChange(e, newValue)}
                    />
                  </div>
                </div>
              </div>

              <div className="w3-margin-top">
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Please provide your valuable feedback"
                  className="textarea-style"
                  style={{ width: "100%" }}
                  name="productExperience"
                  id="desription"
                  multiline
                  rows={8}
                  value={
                    formData.productExperience ? formData.productExperience : ""
                  }
                  errors={errors.productExperience}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
              <div>
                <Button
                  className="none-of-submit init-primary-btn"
                  onClick={() => this.handleSubmit()}
                >
                  Submit
                </Button>
              </div>
              {errors.rating && (
                <div className="error-validate w3-margin-bottom">
                  <ErrorIcon className="margin-right"/>
                  Please provide your feedback!
                </div>
              )}
            </Grid>
          </Grid>

          <Dialog
            open={this.state.setOpen}
            onClose={() => this.handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {this.state.justGiveFeed ? (
              <div className="c-modal-width">
                <CloseIcon
                  className="modal-close-icon"
                  onClick={() => this.handleClose()}
                />
                <div className="vertical-align" style={{ margin: "15px" }}>
                  <div className="c-logo-wrapper">
                    <img
                      className="modal-c-logo"
                      src={clientDetails?.customer?.logo}
                    />
                  </div>
                  <div className="c-logo-wrapper">
                    <img className="modal-fair-logo" src={fairSettleLogo} />
                  </div>
                </div>
                <div className="w3-center w3-padding-16">
                  <div className="w3-center w3-margin-top">
                    <Lottie
                      animationData={SadEmoji}
                      className="sad-emoji-lottie"
                      loop={true}
                    />
                  </div>
                </div>
                <DialogContent className="w3-center color">
                  <DialogContentText id="alert-dialog-description">
                    <div className="margin-bottom-10">
                      <span
                        className="color-grey init-primary-fonts"
                        style={{ fontWeight: "600" }}
                      >
                        Uh-oh! Looks like you are not happy with your shopping
                        experience.
                      </span>
                    </div>
                    <span className="color-dark init-primary-fonts">
                      To serve our customers better, we have partnered with
                      FairSettle.ai to help address your concerns.
                      <br /> Would you like to give FairSettle.ai an opportunity
                      to hear your concern & possibly make it right for you?
                    </span>

                    <div className="w3-margin-top">
                      <DialogActions
                        className="w3-padding-16"
                        style={{ justifyContent: "center" }}
                      >
                        <div>
                          <div>
                            <Button
                              onClick={() => this.openCustomerModal()}
                              variant="contained"
                              className="fs-sad-yes-btn font-bold init-primary-btn"
                              autoFocus
                            >
                              Yes. I would like to continue to FairSettle.ai
                            </Button>
                          </div>
                          <div className="w3-margin-top">
                            <Button
                              onClick={() => this.handleJustGiveFeed()}
                              color=""
                              variant="outlined"
                              className="init-secondary-btn font-bold"
                              style={{
                                minWidth: "200px",
                                border: "1px solid rgb(26 27 36)",
                              }}
                            >
                              No. I would like to submit my feedback as is
                            </Button>
                          </div>
                          <div className="w3-margin-top">
                            <p
                              className="color-grey"
                              style={{ fontSize: "12px" }}
                            >
                              <a
                                style={{ color: "#357EF5" }}
                                href="https://fairsettle.ai/"
                                target="_blank"
                              >
                                www.fairsettle.ai
                              </a>
                              <span style={{ fontStyle: "italic" }}>
                                {" "}
                                is a neutral, third party platform that helps
                                address customer concerns.
                              </span>
                            </p>
                          </div>
                        </div>
                      </DialogActions>
                    </div>
                  </DialogContentText>
                </DialogContent>
              </div>
            ) : (
              <div>
                <div className="w3-center w3-padding ">
                  <div
                    className=" w3-round w3-margin-top"
                    style={{ maxWidth: "500px", margin: "auto" }}
                  >
                    <DialogContent className="w3-center">
                      <DialogContentText id="alert-dialog-description">
                        <div
                          style={{
                            width: "130px",
                            height: "130px",
                            margin: "auto",
                          }}
                        >
                          <Lottie
                            animationData={ThankYou}
                            style={{ height: "130px", width: "130px" }}
                            loop={true}
                          />
                        </div>
                        <div>
                          <p className="color-grey init-primary-fonts font-bold">
                            Thank you for the feedback!
                          </p>
                          <p className="color-dark margin-bottom-25 init-primary-fonts">
                            We did not live up to your expectations this time.
                            We will try harder next time.
                          </p>
                          <Button
                            onClick={this.handleNavigate}
                            color=""
                            variant="outlined"
                            className="init-thank-modal-ok-btn init-primary-fonts"
                          >
                            Close
                          </Button>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
          {showAppMessage && (
            <Dialog
              open={this.state.modalOpen}
              onClose={() => this.handleModalClose()}
            >
              <div className="w3-center w3-padding ">
                <div className=" w3-round w3-margin-top c-modal-width">
                  <DialogContent className="w3-center">
                    <DialogContentText id="alert-dialog-description">
                      <div
                        style={{
                          margin: "auto",
                        }}
                      >
                        <Lottie
                          animationData={ThankYou}
                          style={{ width: "40%", margin: "auto" }}
                          loop={true}
                        />
                      </div>
                      <div>
                        <p className="color-grey init-primary-fonts font-bold">
                          Thank you for the feedback. Keep shopping!
                        </p>
                        
                        <Button
                          onClick={this.handleNavigate}
                          color=""
                          variant="outlined"
                          className="init-thank-modal-ok-btn init-primary-fonts"
                        >
                          Close
                        </Button>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                </div>
              </div>
            </Dialog>
          )}
        </Card>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {
  getRatingTrigger,
  getCustomerList,
  postStoreSessionInfo,
  postClientUpdateTicket,
})(Popup);
