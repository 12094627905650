import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tabs, Tab, Box } from '@material-ui/core';
import { PropTypes } from 'prop-types'; 

export const AppTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#173B57',
    },
})(Tabs);

export const AppTab = withStyles((theme) => ({
    root: {
        '&$selected': {
            color: '#173B57',
            fontWeight: 'bold',
        }
    },
    selected: {},
}))(Tab);

export const AppTabPanel = ({
    children,
    value,
    index,
    ...other
}) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
};

AppTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `app-tabpanel-${index}`,
    };
}
