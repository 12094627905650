import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'hookrouter';
import { Button, Grid } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { ErrorHelperText } from '../Common/HelperInputFields';
import { postCreateZoomMeeting, fetchZoomUserDetails, getTicketById } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';

class ZoomScheduleModal extends React.Component {

    constructor(args) {
        super(args);
        
        this.state = {
            formData: {},
            errors: {},
            showNavigationAlert: false,
            inFlight: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (!localStorage.getItem('zoom_access_token')) {
            this.setState({
                showNavigationAlert: true,
            });
        } else {
            this.props.fetchZoomUserDetails().then(resp => {
                if (resp.data && !resp.data.success) {
                    this.setState({
                        showNavigationAlert: true,
                    });
                }
            });
        }
    }

    validateForm() {
        const { formData, errors } = this.state;
        let formIsValid = true;
        let err = Object.assign({}, errors);
    
        if (!formData.start_time) {
          formIsValid = false;
          err.start_time = 'This field is required';
        }

        const currentTime = new Date().getTime();
        if (formData.start_time && (new Date(formData.start_time).getTime() <= currentTime)) {
            formIsValid = false;
            err.start_time = 'Date and time should be greater than current time';
        }
    
        this.setState({ errors: err });
        return formIsValid;
      }

    handleStartTime(date) {
        const { formData, errors } = this.state;
        formData['start_time'] = date || null;
    
        const err = Object.assign({}, errors);
        if (err['start_time']) {
            err['start_time'] = null;
        }

        this.setState({
            formData,
            errors: err
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const valid = this.validateForm();
        if (!valid) {
            return;
        }
        const { formData, errors } = this.state;

        const { zoomUserDetails, ticketById, handleClose } = this.props;
        if (!zoomUserDetails || !zoomUserDetails.data) {
            this.setState({
                showNavigationAlert: true,
            });
            return;
        }
        const zoomUserData = zoomUserDetails.data.data;
        const password = Math.random().toString(36).substring(7);
        const meetingParams = {
            "topic": "Customer Meeting",
            "type": 1,
            "start_time": formData.start_time,
            "duration": 60,
            "schedule_for": zoomUserData.email,
            "timezone": "Asia/Calcutta",
            "password": password,
            "agenda": "Customer Meeting",
            "settings": {
              "host_video": false,
              "participant_video": false,
              "cn_meeting": false,
              "in_meeting": true,
              "join_before_host": true,
              "mute_upon_entry": true,
              "watermark": false,
              "use_pmi": false,
              "approval_type": 2,
              "registration_type": 3,
              "audio": "both",
              "auto_recording": "cloud",
              "enforce_login": false,
              "enforce_login_domains": "",
              "alternative_hosts": "",
              "global_dial_in_countries": [
                ""
              ],
              "registrants_email_notification": false
            }
        }   
        this.setState({ inFlight: true });

        this.props.postCreateZoomMeeting(zoomUserData.id, meetingParams).then(response => {
            
            const meetingResponse = response && response.data;
            this.setState({ inFlight: false });
            if (!meetingResponse.data || !meetingResponse.success) {
                errors['start_time'] = 'Failed to schedule call';
                this.setState({
                    errors
                });
                return;
            } else if (meetingResponse.data && meetingResponse.success) {
                const startTime = moment(meetingParams.start_time).format('DD/MM/YYYY h:mm a')
                const meetingDetails = Object.assign({}, meetingParams, {
                    meetingResponse: meetingResponse.data
                })
                const ticketDetails = ticketById.data;
                const ticketData = Object.assign({
                    actionToken: ticketDetails.actionToken,
                    scheduleCall: true,
                    meetingParams: meetingDetails,
                    startTime
                });
                handleClose(ticketData);
            }
        })
    }

    render () {
        const { handleClose } = this.props;
        const { formData, errors, showNavigationAlert, inFlight } = this.state;
        return (
            <div style={{ paddingBottom:'20px' }}>
                <Grid container style={{
                    padding: '15px',
                    alignItems: 'center',
                }}>
                    <Grid item xs={12} style={{
                        fontWeight: 600,
                        textAlign: 'center',
                    }}>
                        Schedule Call
                    </Grid>
                </Grid>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                <Grid container>
                    <Grid item xs={12} className="w3-margin-bottom">
                    <Fragment>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                className="ja-date-time-picker"
                                clearable
                                fullWidth
                                required
                                autoOk
                                ampm={true}
                                disablePast
                                inputVariant="outlined"
                                format="MMMM do hh:mm aa"
                                allowKeyboardControl
                                value={(formData && formData.start_time) || null}
                                onChange={date => this.handleStartTime(date)}
                                label="Start Time"
                            />
                        </MuiPickersUtilsProvider>
                        <ErrorHelperText error={errors.start_time}/>
                    </Fragment>
                    </Grid>
                    <Grid item xs={12}>
                    <Grid container spacing={2} justify={'flex-start'}>
                        <Fragment>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleClose()}
                                >Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={inFlight}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                    onClick={e => this.handleSubmit(e)}
                                >Schedule
                                </Button>
                            </Grid>
                        </Fragment>
                    </Grid>
                    </Grid>

                </Grid>
                </form>
                {showNavigationAlert ? (
                    <AlertDialog
                        title="Connect Zoom"
                        message="Please connect to zoom to fix a meeting. On Confirming you will be redirected to settings page!"
                        handleClose={() => navigate('/settings')}
                    />
                ) : null}
            </div>
        )
    }
}



function mapStateToProps(state) {
    return {
        zoomUserDetails: state.zoomUserDetails,
        ticketById: state.ticketById,
    };
}
export default connect(mapStateToProps, {
    postCreateZoomMeeting,
    fetchZoomUserDetails,
    getTicketById,
})(ZoomScheduleModal);