import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, CircularProgress, Button, Chip
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getTicketById, setZoomCallStatus } from '../../Redux/actions';
import { zoomCallTypes, zoomCallStatusText } from '../../config';
import Media from '../Zoom/Media';
import DialogModal from '../Common/DialogModal';
import { AbilityContext } from '../../Ability';
import Ticket from './Ticket.dao';

const CallsRow = ({
    item,
    handlePlay,
    ticketDetails,
    setZoomCallStatus
}) => {
    const { start_time, meetingResponse, meetingRecordings } = item.data;
    const { type } = item;
    const startTime = moment(start_time).format('DD/MM/YYYY hh:mm a');
    let recordingsContent = (
        <Typography variant="body2">No Recordings</Typography>
    );
    if (type === zoomCallTypes.CALL_COMPLETED
        && meetingRecordings
        && meetingRecordings.recording_count > 0
    ) {
        const { recording_files, s3MediaUrl } = meetingRecordings;
        const media = recording_files.find(data => data.status === 'completed' && data.file_type === 'MP4');
        let downloadUrl = media.download_url;
        if (s3MediaUrl) {
            downloadUrl = `/api/v1/ticket/get-attachment?key=${s3MediaUrl}`;
        }
        if (media) {
            recordingsContent = (
                <Typography>
                    <Button
                        color="default"
                        variant="contained"
                        size="small"
                        onClick={() => handlePlay(media, s3MediaUrl)}
                        target="_blank"
                    >Play Recording
                    </Button>
                    <Button
                        className="w3-margin-left"
                        color="default"
                        variant="contained"
                        size="small"
                        startIcon={<GetAppIcon />}
                        target="_blank"
                        href={downloadUrl}
                    >Download
                    </Button>
                </Typography>
            );
        } else {
            recordingsContent = recordingsContent;
        }
    }

    return (
        <AbilityContext.Consumer>{(ability) => {
            const ticket = new Ticket(ticketDetails);
            const isMediator = ability.can('compose', ticket);
            const statusText = (!isMediator && type === zoomCallTypes.START_CALL) ? 'Join Call' : zoomCallStatusText[type];
            let meetingButtonContent = '-';

            if (type !== zoomCallTypes.CALL_COMPLETED
                && type !== zoomCallTypes.CALL_ERROR
                && type !== zoomCallTypes.CALL_ENDED
                && meetingResponse && meetingResponse.start_url
            ) {
                let meetingUrl = meetingResponse.join_url;
                let meetingButtonText = 'Join Call';
                const disableStartBtn = moment(start_time).subtract(2, 'minute').isAfter(new Date())
                if (isMediator) {
                    meetingUrl = meetingResponse.start_url;
                    meetingButtonText = type === zoomCallTypes.CALL_STARTED ? 'Join Call' : 'Start Call';
                }
                meetingButtonContent = (
                    <Button
                        variant="contained"
                        href={meetingUrl}
                        target="_blank"
                        color="primary"
                        onClick={() => setZoomCallStatus({ ticketId: ticketDetails.id })}
                        disabled={type === zoomCallTypes.CALL_SCHEDULED && disableStartBtn}
                    >
                        {meetingButtonText}
                    </Button>
                );
            }
        
            return (
                <TableRow className="cursor-pointer">
                <TableCell>{startTime || '-'}</TableCell>
                <TableCell>
                
                <Chip
                    size="small"
                    icon={type === zoomCallTypes.CALL_COMPLETED ? <DoneIcon /> : null}
                    label={statusText || '-'}
                    color={type === zoomCallTypes.CALL_COMPLETED ? 'primary' : 'default'}
                />
                </TableCell>
                <TableCell>
                    {type !== zoomCallTypes.CALL_COMPLETED ? (
                        <Typography>
                            {meetingButtonContent}
                        </Typography>
                    ) : recordingsContent}
                </TableCell>
            </TableRow>
            )
        }}
        </AbilityContext.Consumer>
    );
}

class ListCalls extends React.Component {
    constructor(args) {
        super(args);
        this.state = {
            ticketDetails: null,
            open: false,
            media: null,
            s3MediaUrl: '',
        }

        this.handlePlay = this.handlePlay.bind(this);
    }

    getTicketDetails() {
        const { ticketId } = this.props;

        this.props.getTicketById(ticketId).then((response) => {
            const resp = response && response.data;
            if (response.status === 200) {
                this.setState({
                    ticketDetails: resp
                });
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ticketId != this.props.ticketId) {
            this.setState({ ticketDetails: null });
            setTimeout(() => {
                this.getTicketDetails();
            }, 1000)
        }
    }

    componentDidMount() {
        this.getTicketDetails();
    }

    handlePlay(media, s3MediaUrl) {
        this.setState({
            open: true,
            media,
            s3MediaUrl
        });
    }

    render() {
        const { ticketDetails, open, media, s3MediaUrl } = this.state;
        const { setZoomCallStatus } = this.props;
        let tabContent = null;
        const emptyContent = (
            <div className="w3-center w3-margin-top w3-padding-32 w3-white">
                No Calls
            </div>
        );

        if (ticketDetails === null || !ticketDetails.ticketTimeline){
            tabContent= <div className="w3-padding-64 w3-center">
                <CircularProgress />
            </div>
        } else if (ticketDetails.ticketTimeline.length === 0) {
            tabContent = emptyContent;
        } else  {
            const calls = ticketDetails.ticketTimeline
                .filter(timeline => timeline.data && timeline.data.type && Object.keys(timeline.data.type).indexOf(zoomCallTypes))
                .sort((a, b) => new Date(b.data.start_time) - new Date(a.data.start_time))
                .map((item, idx) => <CallsRow
                    key={`id_${idx}`}
                    item={item}
                    handlePlay={this.handlePlay}
                    ticketDetails={ticketDetails}
                    setZoomCallStatus={setZoomCallStatus}
                />
            );
            if (calls.length === 0) {
                tabContent = emptyContent
            } else {
                tabContent = (<Paper style={{ flexGrow: 1 }}>
                    <Paper className="margin-50">
                        <TableContainer>
                            <Table className="table-striped">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Scheduled Date</b></TableCell>
                                        <TableCell><b>Status</b></TableCell>
                                        <TableCell><b>Description</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {calls}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Paper>)
            }
        }  

        return (
            <div>
                <div className="w3-row h7t-content w3-margin-top">
                    {tabContent}
                </div>
                {open ? (
                    <DialogModal
                        isOpen={open}
                        hideTitle={true}
                        hideBtns={true}
                        maxWidth="sm"
                        showClose={true}
                        onClose={() => this.setState({ open: false })}
                        dialogContent={(
                            <Media media={media} s3MediaUrl={s3MediaUrl} />
                        )}
                    />)
                : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, {
    getTicketById,
    setZoomCallStatus
})(ListCalls);