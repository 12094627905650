import React from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import queryString from 'querystring';
import { zoomAuthenticate, fetchZoomUserDetails } from '../../Redux/actions';
import Typography from '@material-ui/core/Typography'; 

class Zoom extends React.Component {
    constructor(args) {
        super(args);

        this.state = {
            isAuthenticating: true,
            isCreatingMeeting: false,
        }
    }

    componentDidMount() {
        const searchParams = window.location.search;
        this.setState({ isAuthenticating: true });
        if (localStorage.getItem('zoom_access_token')) {
            this.props.fetchZoomUserDetails().then(resp => {
                if (resp.data && !resp.data.success) {
                    localStorage.removeItem('zoom_access_token');
                }
                this.setState({ isAuthenticating: false });
            });
        } else if (searchParams && searchParams.indexOf('code') !== -1) {
            const queryObj = queryString.decode(searchParams.replace('?code', 'code'));
            const params = {
                code: queryObj.code,
            }
            this.props.zoomAuthenticate(params).then(resp => {
                if (resp.data && resp.data.success) {
                    const authResp = resp.data.data
                    localStorage.setItem('zoom_access_token', authResp.access_token)
                    this.props.fetchZoomUserDetails();
                }
                this.setState({ isAuthenticating: false })
            })
        }
    }

    render() {
        const authLink = `/api/v1/auth/zoom-auth`;
        const { zoomUserDetails } = this.props;
        const { isAuthenticating } = this.props;
        let content = null;
        const progress = (
            <div className="w3-right">
                <CircularProgress />
            </div>
        );

        if (isAuthenticating || (zoomUserDetails && zoomUserDetails.isFetching)) {
            content = progress;
        } else if (zoomUserDetails && zoomUserDetails.data && zoomUserDetails.data.success) {
            const userData = zoomUserDetails.data.data;
            content = (
                <div>
                    <div>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            align="right"
                            paragraph={false}
                        >
                            Connected as {userData.first_name} {userData.last_name}
                        </Typography>
                    </div>
                </div>
            );
        } else {
            content = (
                <Button
                    href={authLink}
                    variant="contained"
                    color="primary"
                    className="w3-right"
                >
                    Connect Zoom
                </Button>
            )
        }
        return (
            <div style={{ flexGrow: 1 }}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12} md={4}>
                        <h3 className="font-bold">Zoom App</h3>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {content}
                    </Grid>
                </Grid>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        zoomUserDetails: state.zoomUserDetails
    };
}
export default connect(mapStateToProps, {
    zoomAuthenticate,
    fetchZoomUserDetails,
})(Zoom);