import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { getTicketById, postUpdateTicket } from '../../Redux/actions';
import { Checkmark } from 'react-checkmark';
import { MediatorTabs } from './MediatorTabs';
import { EditForm as CSEditForm } from './EditForm';
import axios from 'axios';
import { Can } from '../../Ability';

class ViewTicket extends React.Component {
    constructor(args) {
        super(args);
        this.state = {
            ticketDetails: null,
        };
        this.updateTicket = this.updateTicket.bind(this);

    }

    updateTicket(data) {
        const token = localStorage.getItem('fairsettle_access_token');
        return axios.post('/api/v1/ticket/update-ticket', data, { 'headers': { 'Authorization': 'bearer ' + token } }).then(resp => {
            this.setState({ ticketDetails: resp.data.data })
            this.getTicketDetails();
            return resp;
        });

    }

    getTicketDetails() {
        const { id } = this.props;
        this.props.getTicketById(id).then((response) => {
            const resp = response && response.data;
            this.setState({ ticketDetails: resp });
            // if (this.state.ticketDetails && this.state.ticketDetails.attachments && this.state.ticketDetails.attachments.indexOf('/') > 0) {
            //     const filename = this.state.ticketDetails.attachments.split('/');
            //     const res = filename.splice(0, 7);
            //     const imageName = res[5];
            //     const type = imageName.split('.');
            //     this.setState({ imageType: type[1] });
           // }
        });
    }

    componentDidMount() {
        this.getTicketDetails();
    }


    render() {
        const {
            ticketDetails, showAppMessage,
        } = this.state;
        const { tabName } = this.props;

        if (ticketDetails == null) {
            return <div className="w3-center"><CircularProgress /></div>
        }
        return (

            <div className="ticket-margin">
                <Can I="view" a="Dashboard">
                    <MediatorTabs ticketDetails={ticketDetails} postUpdateTicket={this.updateTicket} tabName={tabName} />
                </Can>
                <Can I="view" a="Customer Support">
                    <CSEditForm ticketDetails={ticketDetails} postUpdateTicket={this.updateTicket} />
                </Can>

                {showAppMessage && <div className="w3-center w3-padding ">
                    <div className="w3-card w3-round w3-padding-64 w3-white w3-margin-top" style={{ maxWidth: '600px', margin: 'auto' }}>
                        <div style={{ width: '100px', height: '100px', margin: 'auto' }}>
                            <Checkmark size="xxx-large" />
                        </div>
                        <h4>Updated Successfully</h4>
                    </div>

                </div>}
            </div>
        )
    }
}

export default connect(null, {
    getTicketById,
    postUpdateTicket
})(ViewTicket);