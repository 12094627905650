import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

class Media extends React.Component {

    render() {
        const { s3MediaUrl } = this.props;
        return (
            <div style={{ paddingBottom:'20px' }}>
                <Grid container style={{
                    padding: '15px',
                    alignItems: 'center',
                }}>
                    <Grid item xs={12} style={{
                        fontWeight: 600,
                        textAlign: 'center',
                    }}>
                        Play
                    </Grid>
                </Grid>
                
                <Grid container justify="center">
                    <video width="520" height="340" controls>
                        <source src={`/api/v1/ticket/get-attachment?key=${s3MediaUrl}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, {
})(Media);