import React from 'react';
import Header from '../Components/Common/Header';
import IndexDashboard from '../Components/Common/index';
import Settings from '../Components/Common/Settings';
import ListTickets from '../Components/Support/ListTickets';
import ViewTicket from '../Components/Ticket/View';
import Zoom from '../Components/Zoom';
import { useRedirect, useRoutes , usePath} from 'hookrouter';

import 'react-toastify/dist/ReactToastify.css';

const routes = {
    '/': () => <IndexDashboard />,
    '/dashboard': () => <IndexDashboard />,
    '/settings': () => <Settings />,
    '/enquiries/:clientId?/:type?': ({ clientId, type }: any) => <ListTickets clientId={clientId} type={type} />,
    '/view-ticket/:id/:tabName': ({ client, id, tabName }: any) => <ViewTicket client={client} id={id} tabName={tabName} />,
    '/zoom': () => <Zoom />

};

const AppRoutes = () => {
    useRedirect('/', '/dashboard');
    
    const pages = useRoutes(routes);
    return (
        <div>
            <Header />
            <div className="main-content w3-padding">
                {pages}
            </div>

            <div className="app-footer">
                <div className="copy-right">
                © 2022 FairSettle.ai & its affiliates
                </div>

            </div>
            
        </div>
    );
};

export default AppRoutes;
