import React, { useState, useEffect } from 'react';
import { getCustomerList, getAlldashboardTickets } from '../../Redux/actions';
import { useDispatch } from 'react-redux';
import { Grid, Box, Typography } from '@material-ui/core';
import { navigate} from 'hookrouter';


const Dashboard = (props: any) => {
    const dispatch: any = useDispatch();
    const initialData: any[] = [];
    const [data, setData] = useState(initialData);
    
    useEffect(() => {
        dispatch(getCustomerList()).then((res: any) => {
            if (res && res.data) {
                const customerData = res.data;
                dispatch(getAlldashboardTickets()).then((res: any) => {
                    if (res && res.data) {
                        const countData = res.data;

                        const result = customerData.map((customer:any,idx:any)=>{
                            const foundItem = countData.find((cData:any) => cData.customerid === customer.id)
                            if(foundItem) {
                                customer.countData = foundItem;
                            }
                            return customer;
                        })
                        setData(result)
                        
                    }
                });
            }
        });
     
                
    }, [dispatch]);
    let customersCards: any[] = [];
    if (data && data.length > 0) {
        customersCards = data.map((customers, idx) => (
            <div className="w3-section w3-white w3-round w3-card w3-padding"   key={customers.id} >
                <Grid container justify="space-between">
                    <Grid container item xs={12} sm={12} md={7} alignItems="center"  className="w3-padding">
                        <img className="w3-round " src={customers.logo} style={{ height: "80px", width: 'auto' }} alt="not found" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} className="w3-padding cursor-pointer" onClick= {() => navigate(`/enquiries/${customers.id}/support`)}>
                            <Box display="flex" flexDirection="column" className="w3-light-grey w3-round">
                                <Typography className={`w3-padding `} gutterBottom variant="subtitle1">
                                <div className="w3-center h3-styles-replace ">{(customers && customers.countData && customers.countData.cs_count) ? customers.countData.cs_count : 0 }</div>
                                    <p className="w3-center">From CS</p>
                                </Typography>
                            </Box>
                        </Grid> 
                        <Grid item xs={6} sm={6} md={6} className="w3-padding cursor-pointer" onClick= {() => navigate(`/enquiries/${customers.id}/user`)}>
                            <Box display="flex" flexDirection="column" className="w3-light-grey w3-round">
                                <Typography className={`w3-padding`} gutterBottom variant="subtitle1">
                                    <div className="w3-center h3-styles-replace ">{(customers && customers.countData && customers.countData.enduser_count) ? customers.countData.enduser_count : 0 }</div>
                                    <p className="w3-center">From End user</p>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        ))
    }

    return (
        <div className=" w3-light-grey w3-padding-32">
            {customersCards}
        </div>
    );
};


export default Dashboard;

