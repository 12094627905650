import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import {
    TableContainer, Table, TableHead, Chip, TableRow, TableCell, TableBody, Paper, Typography, Tabs, Tab, Box, CircularProgress, Grid, Button, Modal, TextField, FormControl
} from '@material-ui/core';
import { getAllTickets, getCustomerDetails, postCreateTicket } from '../../Redux/actions';
import moment from 'moment';
import { navigate, useQueryParams } from 'hookrouter';
import { Can } from '../../Ability';
import FilterTickets from './FilterTickets';
import Pagination from '../Common/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({

    paginateTopPadding: {
        paddingTop: '50px'
    },
    paper: {
        position: 'relative',
        width: 400,
        top: '30%',
        left: '38%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '6px',
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));


const TicketRow = ({
    item,
    handleViewComplaint,
}) => {

    return (
        <TableRow className="cursor-pointer" onClick={() => handleViewComplaint(item)}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.emailId}</TableCell>
            <TableCell>{item.subject}</TableCell>
            <TableCell><b className="capitalizeText">{item && item.phase && item.phase.toLowerCase()}</b></TableCell>
            <TableCell>{item.priority ? item.priority : 'High'}</TableCell>
            <TableCell>{item.mediator ? item.mediator.name : <span className="w3-text-grey">-</span>}</TableCell>
            <TableCell>{moment(item.createdAt).fromNow()}</TableCell>
            <TableCell>
                <Chip size="small" className={`w3-margin-right ${item.status === 'closed' ? 'w3-green' : 'w3-yellow'}`} label={<span className="capitalizeText">{item.status}</span>} />
            </TableCell>
        </TableRow>
    );
};

const Tabs2 = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#173B57',
    },
})(Tabs);

const Tab2 = withStyles((theme) => ({
    root: {
        marginRight: theme.spacing(20),
        '&$selected': {
            color: '#173B57',
            fontWeight: 'bold',
        }
    },
    selected: {},
}))(Tab);

const TabPanel = ({
    children,
    value,
    index,
    ...other
}) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ListTickets = (props) => {

    const { clientId, type } = props;
    const [ticketList, setTicketList] = useState(null);
    const [customerDetails, setCustomerDetails] = useState({ name: '' });
    const initialPaginateData = {
        page: 1,
        offset: 0,
        limit: 10
    };
    const initData = {
        product: '',
        firstName: '',
        emailId: ''
    }
    const [qParams, setQueryParams] = useQueryParams();
    const [filterOn, setFilter] = useState(qParams);
    const [showModal, setShowModal] = useState(false);
    const limit = 10;
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(qParams.page || null);
    const classes = useStyles();
    const [formData, setFormData] = useState(initData);
    const [clientKey, setClientKey] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);

    const dispatch = useDispatch();

    const getTickets = (paginateData) => {
        if (!paginateData) {
            paginateData = initialPaginateData;
        }
        getAllTickets(clientId, type, filterOn, paginateData).then((response) => {
            const resp = response && response.data;
            if (resp.success) {
                if (!resp) {
                    return;
                }
                setTicketList(resp.data);
                setTotalCount(resp.total);

            }
        });
    }

    useEffect(() => {
        const { clientId } = props;
        getCustomerDetails(clientId).then((response) => {
            console.log('response: ', response);
            const resp = response && response.data;
            setCustomerDetails(resp);
            setClientKey(resp.key);
            getTickets();
        });
    }, []);

    const handleTicketsFilters = (f) => {
        setQueryParams(f);
        setFilter(f);
    }

    useEffect(() => {
        getTickets();
    }, [clientId, type, filterOn]);

    useEffect(() => {
        const clearData = Object.assign(initialPaginateData, { status: '', phase: '' });
        setQueryParams(clearData);
        setCurrentPage(1);
    }, []);


    const handleViewComplaint = (item) => {
        navigate(`/view-ticket/${item.id}/overview`);
    }

    const handleOpen = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    const handleFormSubmit = (e) => {

        setDisableBtn(true)
        const sendData = {

            product: formData.product,
            firstName: formData.firstName,
            emailId: formData.emailId,
            submitType: "feedback-review",
            client: clientKey
        }
        dispatch(postCreateTicket(sendData)).then((response) => {
            const resp = response && response.data;
            if (resp.success) {
                if (!resp) {
                    return;
                }
                navigate(`/dashboard`);
            }
        })
    }

    const body = (
        <div className={classes.paper}>
            <div>
                <Grid container>
                    <Grid item lg={12} md={12} xs={12} sm={12} style={{ display: 'grid' }}>
                        <FormControl>
                            <TextField name="product" onChange={(e) => handleChange(e)} value={formData.product}
                                className='h7t-modal-content' label="Order Id" size="small" variant="outlined" />
                            <TextField name="firstName" onChange={(e) => handleChange(e)} value={formData.firstName}
                                className='h7t-modal-content' label="Customer Name" size="small" variant="outlined" />
                            <TextField name="emailId" onChange={(e) => handleChange(e)} value={formData.emailId}
                                className='h7t-modal-content' label="Customer Email" size="small" variant="outlined" /> 
                            <Button className='h7t-modal-btn' variant="contained" color="primary" disabled={disableBtn}
                                onClick={() => handleFormSubmit()}>Request Feedback</Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

    const handlePagination = (pageValue, perPage) => {
        let paginateData = {
            page: pageValue,
            offset: perPage,
            limit
        }
        setCurrentPage(pageValue);
        getTickets(paginateData);
        setQueryParams(paginateData);
        setFilter(paginateData);

    }

    let tabContent = null;

    if (ticketList === null) {
        tabContent = <div className="w3-padding-64 w3-center">
            <CircularProgress />
        </div>
    } else if (ticketList.length === 0) {
        tabContent = (
            <div className="w3-center w3-margin-top w3-padding-32 w3-white">
                No Tickets
            </div>
        )
    } else {
        const tickets = ticketList.map((item, idx) => <TicketRow
            key={`id_${idx}`}
            item={item}
            handleViewComplaint={handleViewComplaint}
        />
        );



        tabContent = (<Paper style={{ flexGrow: 1 }} className="w3-margin-top">
            <Paper className="margin-50">
                <TableContainer>
                    <Table className="table-striped">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>ID</b></TableCell>
                                <TableCell><b>Customer Email</b></TableCell>
                                <TableCell><b>Description</b></TableCell>
                                <TableCell><b>Phase</b></TableCell>
                                <TableCell><b>Priority</b></TableCell>
                                <TableCell><b>Assignee</b></TableCell>
                                <TableCell><b>Created</b></TableCell>
                                <TableCell><b>Status</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tickets}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Paper>)
    }
    return (
        <div>
            <div className="w3-margin-bottom double-arrow">
                <Can I="view" a="Dashboard">
                    <Typography className="cursor-pointer dashboard-color lesser"
                        onClick={() => navigate(`/dashboard`)}>Dashboard</Typography>
                </Can>
                <h4><Typography className="customer-name">{customerDetails.name}</Typography></h4>

            </div>
            <div className="h7t-content-btn">
                <div style={{ alignSelf: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleOpen}>Create</Button>
                    <Modal
                        open={showModal}
                        onClose={handleClose}
                    >
                        {body}
                    </Modal>
                </div>
                <FilterTickets
                    filterOn={filterOn}
                    handleTicketsFilters={handleTicketsFilters}
                />
            </div>
            <div className="w3-row h7t-content ">
                <Tabs2
                    value={type}
                    onChange={(e, newValue) => navigate(`/enquiries/${clientId}/${newValue}`)}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Tickets from customer"
                    className="tab-border"
                >
                    <Tab2 className="capitalizeText" value="user" label="Cases from End User" />
                    <Tab2 className="capitalizeText" value="support" label="Cases from CS" />

                </Tabs2>
                {tabContent}
            </div>
            {(ticketList && ticketList.length > 0 && totalCount > limit) && (

                <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
                    <Pagination
                        cPage={currentPage}
                        defaultPerPage={limit}
                        data={{ totalCount }}
                        onChange={handlePagination}
                    />
                </Grid>)}
        </div>
    );
}


export default ListTickets;