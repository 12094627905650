import React from 'react';
import {Box,Paper} from '@material-ui/core';
import moment from 'moment';

export const TicketInfo = ({ticketDetails}) => {
    const resolvedBy = ticketDetails.phase === 'Tier 3' ? 'Mediator' : 'Customer Support';
    return  <Paper style={{ flexGrow: 1 }} className="w3-margin-top">
        <Box component="div" display="flex" flexDirection="column" className="w3-padding-32 padding-left-32">
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Attend by:</div>
                <div className="font-weight-600 width-60">{ticketDetails.mediator ? ticketDetails.mediator.name : '-'}</div>
                <Box component="div" display="flex" justifyContent="flex-end">
                    {ticketDetails && ticketDetails.customers ? <img src={ticketDetails.customers.logo} style={{ height: "43px", width: 'auto' }} alt="not found" /> : ''}
                </Box>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Customer name:</div>
                <div className="font-weight-600">{ticketDetails.firstName}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Customer email Id:</div>
                <div className="font-weight-600">{ticketDetails.emailId}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Customer phone number:</div>
                <div className="font-weight-600">{ticketDetails.phoneNumber}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Merchant:</div>
                <div className="font-weight-600">{ticketDetails.merchant}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Product:</div>
                <div className="font-weight-600">{ticketDetails.product}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Merchant description:</div>
                <div className="" style={{ whiteSpace: 'pre-wrap' }}>{ticketDetails.merchantExperience}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25" >Complaint description:</div>
                <div className="" style={{whiteSpace:'pre-wrap'}}>{ticketDetails.productExperience}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Created:</div>
                <div className="font-weight-600">{moment(ticketDetails.createdAt).fromNow()}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Merchant email Id:</div>
                <div className="font-weight-600">{ticketDetails.merchantEmailId ? ticketDetails.merchantEmailId : '-'}</div>
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" className="margin-bottom-25">
                <div className="width-25">Final Resolution:</div>
                {ticketDetails && ticketDetails.resolutionOffered ? <div className="font-weight-600">Resolved by {resolvedBy} in {ticketDetails.phase} and the resolution is - {ticketDetails.resolutionOffered ? ticketDetails.resolutionOffered : '-'}</div>: '-'}
            </Box>
            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
                <div className="width-25">Attachment:</div>
                {(ticketDetails && ticketDetails.attachments && ticketDetails.attachments.indexOf('/') >= 0 ?
                    <a href={`/api/v1/ticket/get-attachment?key=${ticketDetails.attachments}`} target="_blank">Click on the link to download</a>
                    : '-')}

            </Box>
        </Box>
    </Paper>
}