import React from 'react';
import { Grid, Card, CardContent, CardActions, Button, CircularProgress } from '@material-ui/core';
import { Checkmark } from 'react-checkmark';
import { postUserUpdateTicket } from '../../Redux/actions';
import { connect } from 'react-redux';
import axios  from 'axios';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

class ClosureLink extends React.Component {
    constructor(args) {
        super(args);
        this.state = {
            message: null,
            clsMessage:null,
            ticketDetails: null
        }
        this.handleClosure = this.handleClosure.bind(this); 
    }

    handleClosure(state) {
        const { token } = this.props;
        const updateTicket = {
            status: state,
            actionToken: token,
        }
        this.props.postUserUpdateTicket(updateTicket).then((response) => {
            const resp = response && response.data;
            const message = resp.next === 'closed' ? 'Thank you for your feedback, hope you continue enjoying our services' : 
            ' We are sorry your issue has not been resolved yet, we will get in touch with via your registered email to resolve it at the earliest';
        
            this.setState({
                clsMessage:resp.next === 'closed' ? 'w3-text-green':'w3-text-red',
                message,
            });
        });
    }
    
    componentDidMount() {
        const { token } = this.props;
        axios.get(`/api/v1/ticket/with-token/${token}`).then(resp=>{
            this.setState({ticketDetails:resp.data});
        }).catch(ex =>{
            this.setState({
                ticketDetails:false,
                clsMessage :'w3-text-red',
                message:'We are unable to get required details, Please check if you have already updated your preference'
            });
        })
    }
    render() {
        const { message,clsMessage, ticketDetails } = this.state;

        if (ticketDetails === null) {
            return <div className="w3-center w3-padding-32 marginTop100"><CircularProgress /></div>
        }

        return (
            <div>
                {!message && <div>
                    <Grid container className="marginTop100">
                        <Grid item xs={12} md={4} className="marginAuto marginTop50">
                            <Card>
                                <CardContent>
                                    <p>Please let us know the following issue <b>has been resolved to your satisfaction</b>.</p>  
                                    {ticketDetails && <b>Ticket {ticketDetails.id} - {ticketDetails.productExperience}</b>}
                                </CardContent>

                                <CardActions className="padding16 w3-center w3-right">
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        //style={{ margin: 'auto' }}
                                        onClick={(e) => this.handleClosure('next_phase')}
                                    >No
                            </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        //style={{ margin: 'auto' }}
                                        onClick={(e) => this.handleClosure('closed')}
                                    >Yes
                            </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>}
                {message  && <div className="w3-center w3-padding " style={{ marginTop: '100px' }}>
                    <div className="w3-card w3-round w3-padding-64 w3-white w3-margin-top" style={{ maxWidth: '600px', margin: 'auto' }}>
                         <div style={{ width: '100px', height: '100px', margin: 'auto' }}>
                            {clsMessage === "w3-text-green" ? <Checkmark size="xx-large" /> : <ErrorOutlineIcon color="error" className="fontSize60" />}
                        </div>
                        <h4 className={`w3-padding ${clsMessage}`}>
                            {message}
                        </h4>
                    </div>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, {
    postUserUpdateTicket,
})(ClosureLink);
