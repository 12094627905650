import React, { useState, useEffect } from 'react';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import { navigate } from 'hookrouter';
import { Messages as TicketMessages } from './Messages';
import ZoomScheduleModal from './ZoomScheduleModal';
import { Timelines as TicketTimeline } from './Timeline';
import { Can, AbilityContext } from '../../Ability';

import { AppTabs, AppTab, AppTabPanel, a11yProps } from '../Common/AppTabs';
import DialogModal from '../Common/DialogModal';
import ListCalls from '../Ticket/ListCalls'
import { zoomCallTypes } from '../../config';
import GroupIcon from '@material-ui/icons/Group';
import Ticket from './Ticket.dao';
import { TicketInfo } from './Info';
import { AttorneyModal } from './AttorneyModal';

export const MediatorTabs = ({ ticketDetails, postUpdateTicket, tabName }) => {
    const TabNames = {
        'overview': 0,
        'timeline': 1,
        'message': 2,
        'call': 3
    }
    const [tabNumber, setTabNumber] = useState(TabNames[tabName]);
    const [callDetails, setCallDetails] = useState(null);
    const [isCallScheduled, setCallScheduledFlag] = useState(false);
    const [open, setOpen] = useState(false);
    const { id } = ticketDetails;
    const [showModal, setShowModal] = useState(false);

    const TabValues = {
        0: 'overview',
        1: 'timeline',
        2: 'message',
        3: 'call',
    }

    const disabled = ticketDetails && ticketDetails.status === "closed" ? true : false;

    const handleClose = () => {
        setShowModal(false);
    }
    // FIXIT - Tejaswini - Move this to separate modal component
    // TODO - Refactor the component

    useEffect(() => {
        const isCallScheduled = ticketDetails.ticketTimeline
            && ticketDetails.ticketTimeline.some(timeline => (
                timeline.type === zoomCallTypes.CALL_SCHEDULED ||
                timeline.type === zoomCallTypes.CALL_STARTED ||
                timeline.type === zoomCallTypes.START_CALL
            ));
        setCallScheduledFlag(isCallScheduled);
    }, [ticketDetails])

    const handleComposeMessage = callDetails => {
        setOpen(false);
        if (!callDetails) {
            return;
        }
        const tab = TabNames['message'];
        setCallDetails(callDetails);
        setTabNumber(tab);
        navigate(`/view-ticket/${id}/${TabValues[tab]}`);
    }

    return (
        <AbilityContext.Consumer>{(ability) => {
            const ticket = new Ticket(ticketDetails);
            const disableScheduleBtn = (isCallScheduled || disabled || !(ability.can('compose', ticket)));
            return <div>
                <div className="w3-margin-bottom double-arrow">
                    <Can I="view" a="Dashboard">
                        <Typography className="cursor-pointer dashboard-color lesser" onClick={() => navigate(`/dashboard`)}>Dashboard</Typography>
                    </Can>
                    <h4><Typography className="complaint-name"><b>Ticket :  {`${ticketDetails.id}, from ${ticketDetails.emailId}`}</b></Typography></h4>
                    <Grid container justify="flex-end" alignItems="center" spacing={3}>

                        <Grid item container xs={6} md={6} justify="flex-end">
                            <Chip size="small" className={`w3-margin-right ${ticketDetails.status === 'closed' ? 'w3-green' : 'w3-yellow'}`} label={<span className="capitalizeText">{ticketDetails.status}</span>} />
                            <Chip size="small" className="w3-blue" label={ticketDetails.phase} />
                        </Grid>
                        <Can I="view" a="User management">
                            {ticketDetails && ticketDetails.phase === 'Tier 3' && ticketDetails.mediatorId == null ? <Grid item>
                                <div className="query flex-style cursor-pointer" onClick={() => { setShowModal(true); }}>
                                    <span><GroupIcon /></span>
                                    <span style={{ margin: 5 }}>Assign to</span>
                                </div>
                            </Grid> : ''}
                        </Can>
                    </Grid>

                </div>
                <div className="w3-row h7t-content w3-margin-top">
                    <AppTabs
                        value={tabNumber}
                        onChange={(e, tab) => {
                            setTabNumber(tab);
                            navigate(`/view-ticket/${id}/${TabValues[tab]}`);
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="simple tabs example"
                        className="tab-border"
                    >
                        <AppTab className="capitalizeText" label="Overview" {...a11yProps(0)} />
                        <AppTab className="capitalizeText" label="Timeline" {...a11yProps(1)} />
                        <AppTab className="capitalizeText" label="Message" {...a11yProps(2)} />
                        <AppTab className="capitalizeText" label="Call" {...a11yProps(3)} />
                    </AppTabs>
                    <AppTabPanel value={tabNumber} index={0}>
                        <TicketInfo ticketDetails={ticketDetails} />
                    </AppTabPanel>

                    <AppTabPanel value={tabNumber} index={1}>
                        <Paper style={{ flexGrow: 1 }} className="w3-margin-top">
                            <TicketTimeline ticketDetails={ticketDetails} />
                        </Paper>
                    </AppTabPanel>

                    <AppTabPanel value={tabNumber} index={2}>
                        <TicketMessages
                            ticketDetails={ticketDetails}
                            postUpdateTicket={postUpdateTicket}
                            callDetails={callDetails}
                            resetCallDetails={() => setCallDetails(null)}
                        />
                    </AppTabPanel>

                    <AppTabPanel value={tabNumber} index={3}>
                        <Grid
                            container
                            spacing={1}
                            alignContent="center"
                            justify="flex-end"
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                className="w3-margin-top"
                                disabled={disableScheduleBtn}
                                onClick={() => {
                                    if (disableScheduleBtn) {
                                        return;
                                    }
                                    setOpen(true);
                                }}
                            >Schedule Call
                            </Button>
                        </Grid>
                        <Paper style={{ flexGrow: 1 }} className="w3-margin-top">
                            <ListCalls ticketId={id} />
                        </Paper>
                    </AppTabPanel>
                </div>
                {showModal ? (<DialogModal
                    fullWidth={true}
                    isOpen={showModal}
                    maxWidth="sm"
                    showClose={showModal}
                    hidetitle={false}
                    dialogContent={(
                        <AttorneyModal
                        handleClose={handleClose}
                        ticketDetails={ticketDetails}
                        postUpdateTicket={postUpdateTicket}
                        />
                    )}
                    hideBtns={true}
                    hideSecondaryBtn={true}
                    onClose={() => setShowModal(false)}

                />) : null}
                {open ? (
                    <DialogModal
                        fullWidth
                        isOpen={open}
                        hideTitle={true}
                        hideBtns={true}
                        maxWidth="sm"
                        dialogTitle="Schedule Meeting"
                        showClose={true}
                        onClose={() => setOpen(false)}
                        dialogContent={(
                            <ZoomScheduleModal
                                handleClose={callDetails => handleComposeMessage(callDetails)}
                                postUpdateTicket={postUpdateTicket}
                            />
                        )}
                    />)
                    : null}

            </div>
        }}</AbilityContext.Consumer>
    );
}
